import React from 'react';
import NewsCard from '../Components/NewsCard';
import { Link } from 'react-router-dom';

import logoSvg from '../Assets/logoPartners.svg';

import MemberCard from '../Components/MemberCard';

function Home(props) {
	return (
		<div className="home">
			<div className="home-banner">
				<div className="home-banner__container">
					<div className="home-banner__content">
						<div className="home-banner__head">
							<h2 className="home-banner__head-top">
								<span>СОЮЗ</span> ПРОФЕССИОНАЛЬНЫХ{' '}
							</h2>
							<h2 className="home-banner__head-bot">ОРГАНИЗАТОРОВ ТОРГОВ</h2>
							<p className="home-banner__head-par">
								гарант экономического прогресса <br />и правовой справедливости
							</p>
						</div>
						<div className="home-banner__info">
							<div className="home-banner__info-stick"></div>
							<p className="home-banner__info-desc">
								Что может быть эффективнее, чем регулирование <br />
								профессионального сообщества самими <br />
								профессионалами
							</p>
						</div>

						<button onClick={props.showModal} className="home-banner__btn btn">
							Вступить в СПОТ
						</button>
					</div>

					<div className="home-banner__list">
						<div className="home-banner__list-item">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="47"
								height="47"
								viewBox="0 0 47 47"
								fill="none">
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M47 7.90553C47 11.8248 44.1479 15.0779 40.4059 15.7028C44.1479 16.3276 47 19.5808 47 23.5C47 27.4192 44.1479 30.6724 40.4059 31.2972C44.1479 31.9221 47 35.1753 47 39.0945C47 43.4605 43.4605 47 39.0945 47C35.1753 47 31.9221 44.1479 31.2972 40.4059C30.6724 44.1479 27.4192 47 23.5 47C19.5808 47 16.3276 44.1479 15.7028 40.4059C15.0779 44.1479 11.8248 47 7.90553 47C3.53943 47 0 43.4605 0 39.0945C0 35.1753 2.85208 31.9221 6.59397 31.2972C2.85208 30.6724 0 27.4192 0 23.5C0 19.5808 2.85208 16.3276 6.59397 15.7028C2.85208 15.0779 0 11.8248 0 7.90553C0 3.53943 3.53943 0 7.90553 0C11.8248 0 15.0779 2.85208 15.7028 6.59397C16.3276 2.85208 19.5808 0 23.5 0C27.4192 0 30.6724 2.85208 31.2972 6.59397C31.9221 2.85208 35.1753 0 39.0945 0C43.4605 0 47 3.53943 47 7.90553ZM15.7028 9.21709C15.1492 12.5324 12.5324 15.1492 9.21709 15.7028C12.5324 16.2564 15.1492 18.8732 15.7028 22.1886C16.2564 18.8732 18.8732 16.2564 22.1886 15.7028C18.8732 15.1492 16.2564 12.5324 15.7028 9.21709ZM24.8115 15.7028C28.1268 15.1492 30.7436 12.5324 31.2972 9.21709C31.8508 12.5324 34.4677 15.1492 37.783 15.7028C34.4677 16.2564 31.8508 18.8732 31.2972 22.1886C30.7436 18.8732 28.1268 16.2564 24.8115 15.7028ZM31.2972 37.783C31.8508 34.4677 34.4677 31.8508 37.783 31.2972C34.4677 30.7436 31.8508 28.1268 31.2972 24.8115C30.7436 28.1268 28.1268 30.7436 24.8115 31.2972C28.1268 31.8508 30.7436 34.4677 31.2972 37.783ZM15.7028 24.8115C16.2564 28.1268 18.8732 30.7436 22.1886 31.2972C18.8732 31.8508 16.2564 34.4677 15.7028 37.783C15.1492 34.4677 12.5324 31.8508 9.21709 31.2972C12.5324 30.7436 15.1492 28.1268 15.7028 24.8115Z"
									fill="url(#paint0_linear_793_914)"
								/>
								<defs>
									<linearGradient
										id="paint0_linear_793_914"
										x1="5.63134"
										y1="-8.6636"
										x2="49.8157"
										y2="67.1429"
										gradientUnits="userSpaceOnUse">
										<stop stopColor="#01F1FD" />
										<stop offset="1" stopColor="#076BF8" />
									</linearGradient>
								</defs>
							</svg>
							<p className="home-banner__list-item-par">
								развитие рынка <br />
								электронных торгов
							</p>
						</div>
						<div className="home-banner__list-item">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="47"
								height="47"
								viewBox="0 0 47 47"
								fill="none">
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M47 7.90553C47 11.8248 44.1479 15.0779 40.4059 15.7028C44.1479 16.3276 47 19.5808 47 23.5C47 27.4192 44.1479 30.6724 40.4059 31.2972C44.1479 31.9221 47 35.1753 47 39.0945C47 43.4605 43.4605 47 39.0945 47C35.1753 47 31.9221 44.1479 31.2972 40.4059C30.6724 44.1479 27.4192 47 23.5 47C19.5808 47 16.3276 44.1479 15.7028 40.4059C15.0779 44.1479 11.8248 47 7.90553 47C3.53943 47 0 43.4605 0 39.0945C0 35.1753 2.85208 31.9221 6.59397 31.2972C2.85208 30.6724 0 27.4192 0 23.5C0 19.5808 2.85208 16.3276 6.59397 15.7028C2.85208 15.0779 0 11.8248 0 7.90553C0 3.53943 3.53943 0 7.90553 0C11.8248 0 15.0779 2.85208 15.7028 6.59397C16.3276 2.85208 19.5808 0 23.5 0C27.4192 0 30.6724 2.85208 31.2972 6.59397C31.9221 2.85208 35.1753 0 39.0945 0C43.4605 0 47 3.53943 47 7.90553ZM15.7028 9.21709C15.1492 12.5324 12.5324 15.1492 9.21709 15.7028C12.5324 16.2564 15.1492 18.8732 15.7028 22.1886C16.2564 18.8732 18.8732 16.2564 22.1886 15.7028C18.8732 15.1492 16.2564 12.5324 15.7028 9.21709ZM24.8115 15.7028C28.1268 15.1492 30.7436 12.5324 31.2972 9.21709C31.8508 12.5324 34.4677 15.1492 37.783 15.7028C34.4677 16.2564 31.8508 18.8732 31.2972 22.1886C30.7436 18.8732 28.1268 16.2564 24.8115 15.7028ZM31.2972 37.783C31.8508 34.4677 34.4677 31.8508 37.783 31.2972C34.4677 30.7436 31.8508 28.1268 31.2972 24.8115C30.7436 28.1268 28.1268 30.7436 24.8115 31.2972C28.1268 31.8508 30.7436 34.4677 31.2972 37.783ZM15.7028 24.8115C16.2564 28.1268 18.8732 30.7436 22.1886 31.2972C18.8732 31.8508 16.2564 34.4677 15.7028 37.783C15.1492 34.4677 12.5324 31.8508 9.21709 31.2972C12.5324 30.7436 15.1492 28.1268 15.7028 24.8115Z"
									fill="url(#paint0_linear_793_914)"
								/>
								<defs>
									<linearGradient
										id="paint0_linear_793_914"
										x1="5.63134"
										y1="-8.6636"
										x2="49.8157"
										y2="67.1429"
										gradientUnits="userSpaceOnUse">
										<stop stopColor="#01F1FD" />
										<stop offset="1" stopColor="#076BF8" />
									</linearGradient>
								</defs>
							</svg>
							<p className="home-banner__list-item-par">
								оздоровление экономики <br />
								всего государства
							</p>
						</div>
						<div className="home-banner__list-item">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="47"
								height="47"
								viewBox="0 0 47 47"
								fill="none">
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M47 7.90553C47 11.8248 44.1479 15.0779 40.4059 15.7028C44.1479 16.3276 47 19.5808 47 23.5C47 27.4192 44.1479 30.6724 40.4059 31.2972C44.1479 31.9221 47 35.1753 47 39.0945C47 43.4605 43.4605 47 39.0945 47C35.1753 47 31.9221 44.1479 31.2972 40.4059C30.6724 44.1479 27.4192 47 23.5 47C19.5808 47 16.3276 44.1479 15.7028 40.4059C15.0779 44.1479 11.8248 47 7.90553 47C3.53943 47 0 43.4605 0 39.0945C0 35.1753 2.85208 31.9221 6.59397 31.2972C2.85208 30.6724 0 27.4192 0 23.5C0 19.5808 2.85208 16.3276 6.59397 15.7028C2.85208 15.0779 0 11.8248 0 7.90553C0 3.53943 3.53943 0 7.90553 0C11.8248 0 15.0779 2.85208 15.7028 6.59397C16.3276 2.85208 19.5808 0 23.5 0C27.4192 0 30.6724 2.85208 31.2972 6.59397C31.9221 2.85208 35.1753 0 39.0945 0C43.4605 0 47 3.53943 47 7.90553ZM15.7028 9.21709C15.1492 12.5324 12.5324 15.1492 9.21709 15.7028C12.5324 16.2564 15.1492 18.8732 15.7028 22.1886C16.2564 18.8732 18.8732 16.2564 22.1886 15.7028C18.8732 15.1492 16.2564 12.5324 15.7028 9.21709ZM24.8115 15.7028C28.1268 15.1492 30.7436 12.5324 31.2972 9.21709C31.8508 12.5324 34.4677 15.1492 37.783 15.7028C34.4677 16.2564 31.8508 18.8732 31.2972 22.1886C30.7436 18.8732 28.1268 16.2564 24.8115 15.7028ZM31.2972 37.783C31.8508 34.4677 34.4677 31.8508 37.783 31.2972C34.4677 30.7436 31.8508 28.1268 31.2972 24.8115C30.7436 28.1268 28.1268 30.7436 24.8115 31.2972C28.1268 31.8508 30.7436 34.4677 31.2972 37.783ZM15.7028 24.8115C16.2564 28.1268 18.8732 30.7436 22.1886 31.2972C18.8732 31.8508 16.2564 34.4677 15.7028 37.783C15.1492 34.4677 12.5324 31.8508 9.21709 31.2972C12.5324 30.7436 15.1492 28.1268 15.7028 24.8115Z"
									fill="url(#paint0_linear_793_914)"
								/>
								<defs>
									<linearGradient
										id="paint0_linear_793_914"
										x1="5.63134"
										y1="-8.6636"
										x2="49.8157"
										y2="67.1429"
										gradientUnits="userSpaceOnUse">
										<stop stopColor="#01F1FD" />
										<stop offset="1" stopColor="#076BF8" />
									</linearGradient>
								</defs>
							</svg>
							<p className="home-banner__list-item-par">
								оперативная реакция на <br />
								изменения законодательства
							</p>
						</div>
						<div className="home-banner__list-item">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="47"
								height="47"
								viewBox="0 0 47 47"
								fill="none">
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M47 7.90553C47 11.8248 44.1479 15.0779 40.4059 15.7028C44.1479 16.3276 47 19.5808 47 23.5C47 27.4192 44.1479 30.6724 40.4059 31.2972C44.1479 31.9221 47 35.1753 47 39.0945C47 43.4605 43.4605 47 39.0945 47C35.1753 47 31.9221 44.1479 31.2972 40.4059C30.6724 44.1479 27.4192 47 23.5 47C19.5808 47 16.3276 44.1479 15.7028 40.4059C15.0779 44.1479 11.8248 47 7.90553 47C3.53943 47 0 43.4605 0 39.0945C0 35.1753 2.85208 31.9221 6.59397 31.2972C2.85208 30.6724 0 27.4192 0 23.5C0 19.5808 2.85208 16.3276 6.59397 15.7028C2.85208 15.0779 0 11.8248 0 7.90553C0 3.53943 3.53943 0 7.90553 0C11.8248 0 15.0779 2.85208 15.7028 6.59397C16.3276 2.85208 19.5808 0 23.5 0C27.4192 0 30.6724 2.85208 31.2972 6.59397C31.9221 2.85208 35.1753 0 39.0945 0C43.4605 0 47 3.53943 47 7.90553ZM15.7028 9.21709C15.1492 12.5324 12.5324 15.1492 9.21709 15.7028C12.5324 16.2564 15.1492 18.8732 15.7028 22.1886C16.2564 18.8732 18.8732 16.2564 22.1886 15.7028C18.8732 15.1492 16.2564 12.5324 15.7028 9.21709ZM24.8115 15.7028C28.1268 15.1492 30.7436 12.5324 31.2972 9.21709C31.8508 12.5324 34.4677 15.1492 37.783 15.7028C34.4677 16.2564 31.8508 18.8732 31.2972 22.1886C30.7436 18.8732 28.1268 16.2564 24.8115 15.7028ZM31.2972 37.783C31.8508 34.4677 34.4677 31.8508 37.783 31.2972C34.4677 30.7436 31.8508 28.1268 31.2972 24.8115C30.7436 28.1268 28.1268 30.7436 24.8115 31.2972C28.1268 31.8508 30.7436 34.4677 31.2972 37.783ZM15.7028 24.8115C16.2564 28.1268 18.8732 30.7436 22.1886 31.2972C18.8732 31.8508 16.2564 34.4677 15.7028 37.783C15.1492 34.4677 12.5324 31.8508 9.21709 31.2972C12.5324 30.7436 15.1492 28.1268 15.7028 24.8115Z"
									fill="url(#paint0_linear_793_914)"
								/>
								<defs>
									<linearGradient
										id="paint0_linear_793_914"
										x1="5.63134"
										y1="-8.6636"
										x2="49.8157"
										y2="67.1429"
										gradientUnits="userSpaceOnUse">
										<stop stopColor="#01F1FD" />
										<stop offset="1" stopColor="#076BF8" />
									</linearGradient>
								</defs>
							</svg>
							<p className="home-banner__list-item-par">
								инновационные программно-
								<br />
								технические разработки
							</p>
						</div>
					</div>
				</div>
			</div>

			<div className="home-news">
				<div className="home-news__container">
					<div className="home-news__head">
						<h2 className="home-news__head-head">СВЕЖИЕ НОВОСТИ </h2>
					</div>
					<div className="home-news__news-cards">
						<NewsCard
							background="#ffffff"
							head="Баннер с новостью"
							descHead="Единые правила игры против негативных тенденций"
							desc="В пятницу, 18 февраля, прошло заседание Экспертного совета по развитию конкуренции в сфере банкротства Комитета ГД."
						/>
						<NewsCard
							background="#ffffff"
							head="Баннер с новостью"
							descHead="Единые правила игры против негативных тенденций"
							desc="В пятницу, 18 февраля, прошло заседание Экспертного совета по развитию конкуренции в сфере банкротства Комитета ГД."
						/>
						<NewsCard
							background="#ffffff"
							head="Баннер с новостью"
							descHead="Единые правила игры против негативных тенденций"
							desc="В пятницу, 18 февраля, прошло заседание Экспертного совета по развитию конкуренции в сфере банкротства Комитета ГД."
						/>
					</div>
					<div className="home-news__more">
						<Link to="/news">
							Читать предыдущие новости{' '}
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="23"
								height="23"
								viewBox="0 0 23 23"
								fill="none">
								<path
									d="M0.0603398 21.0322C0.0484623 21.5843 0.48649 22.0415 1.0387 22.0534L10.0375 22.247C10.5897 22.2589 11.047 21.8209 11.0589 21.2688C11.0708 20.7167 10.6328 20.2595 10.0805 20.2476L2.08159 20.0756L2.25364 12.0782C2.26552 11.5261 1.82749 11.0689 1.27528 11.057C0.723064 11.0452 0.265778 11.4831 0.253901 12.0352L0.0603398 21.0322ZM21.3082 0.277918L0.368403 20.3316L1.75202 21.7758L22.6918 1.72208L21.3082 0.277918Z"
									fill="#0D2461"
								/>
							</svg>
						</Link>
					</div>
				</div>
			</div>

			<div className="home-members">
				<div className="home-members__container">
					<div className="home-members__head">
						<h2 className="home-members__head-head">ЧЛЕНЫ СОЮЗА</h2>
					</div>

					<div className="home-members__cards">
						<MemberCard img={logoSvg} />
						<MemberCard img={logoSvg} />
						<MemberCard img={logoSvg} />
						<MemberCard img={logoSvg} />
						<MemberCard img={logoSvg} />
						<MemberCard img={logoSvg} />
						<MemberCard img={logoSvg} />
						<MemberCard img={logoSvg} />
					</div>

					<div className="home-members__btn">
						<Link to="/contacts" className="home-members__btn-btn btn">
							Вступить в СПОТ
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Home;
