import React from 'react';
import { Link } from 'react-router-dom';

function Members(props) {
	return (
		<div className="members">
			<div className="members-banner">
				<div className="members-banner__container">
					<div className="members-banner__head">
						<h1 className="members-banner__head-top">
							<span>ЧЛЕНЫ</span> СОЮЗА ПРОФЕССИОНАЛЬНЫХ
						</h1>
						<h2 className="members-banner__head-bot">ОРГАНИЗАТОРОВ ТОРГОВ</h2>
					</div>

					<div className="members-banner__desc">
						<div className="members-banner__desc-stick"></div>
						<p className="members-banner__desc-text">
							Союз профессиональных организаторов торгов является некоммерческой <br />
							организацией, основанной на членстве, созданной в целях защиты общих, <br />в том
							числе профессиональных, интересов членов, а также для <br />
							регулирования деятельности своих членов.
						</p>
					</div>

					<div className="members-banner__btn">
						<Link onClick={props.showModal} to="" className="btn">
							Вступить в СПОТ
						</Link>
					</div>
				</div>
			</div>

			<div className="members-rights">
				<div className="members-rights__container">
					<h2 className="members-rights__head">ПРАВА И ОБЯЗАННОСТИ</h2>
					<div className="members-rights__info">
						<p className="members-rights__info-text">
							<span>Членами СПОТ</span> могут быть физические лица, достигшие <br />
							возраста 18 (восемнадцати) лет и юридические лица, <br />
							принятые в Союз решением Совета Союза.
						</p>
					</div>
					<p className="members-rights__desc">Члены Союза профессиональных организаторов торгов:</p>
					<div className="members-rights__content">
						<div className="members-rights__content-left">
							<ul className="members-rights__content-list">
								<span>имеют право:</span>
								<li className="members-rights__content-list-item">
									участвовать в управлении делами Союза, в том числе <br />
									вносить на рассмотрение органов Союза предложения по <br />
									всем вопросам деятельности Союза, участвовать в их <br />
									обсуждении и принятии решений;
								</li>
								<li className="members-rights__content-list-item">
									получать информацию о деятельности Союза, знакомиться <br />с ее бухгалтерской и
									иной документацией, пользоваться в <br />
									первоочередном порядке услугами, предоставляемыми <br />
									Союзом, указывать на своих бланках и печатях свою <br />
									принадлежность к Союзу;
								</li>
								<li className="members-rights__content-list-item">
									обжаловать решения органов управления Союза, влекущие <br />
									гражданско-правовые последствия, в случаях и в порядке, <br />
									которые предусмотрены законом;
								</li>
								<li className="members-rights__content-list-item">
									требовать, действуя от имени Союза, возмещения <br />
									причиненных Союза убытков;
								</li>
								<li className="members-rights__content-list-item">
									оспаривать, действуя от имени Союза, совершенные ею <br />
									сделки по основаниям, предусмотренным ст. 174 ГК РФ или <br />
									законами о корпорациях отдельных организационно-
									<br />
									правовых форм, и требовать применения последствий их <br />
									недействительности, а также применения последствий <br />
									недействительности ничтожных сделок Союза;
								</li>
								<li className="members-rights__content-list-item">
									вносить вклады в имущество Союза для обеспечения <br />
									функционирования Союза;
								</li>
								<li className="members-rights__content-list-item">
									пользоваться в полном объеме деловой информацией, <br />
									имеющейся в Союза, а также результатами деятельности <br />
									Союза;
								</li>
								<li className="members-rights__content-list-item">
									получать от Союза необходимые консультации;
								</li>
								<li className="members-rights__content-list-item">
									в любое время выйти из состава Союза.
								</li>
							</ul>
						</div>
						<div className="members-rights__content-right">
							<ul className="members-rights__content-list">
								<span>обязаны: </span>
								<li className="members-rights__content-list-item">
									соблюдать действующее законодательство Российской <br />
									Федерации, настоящий Устав и другие акты, принятые <br />
									органами Союза в рамках их компетенций;
								</li>
								<li className="members-rights__content-list-item">
									учитывать общественное мнение и социальные последствия <br />
									результатов своей деятельности при решении задач Союза;
								</li>
								<li className="members-rights__content-list-item">
									уважать интересы других членов Союза, соблюдать условия <br />
									подписанных в рамках Союза договоров и соглашений;
								</li>
								<li className="members-rights__content-list-item">
									участвовать в образовании имущества Союза, в том числе <br />
									уплачивать членские взносы;
								</li>
								<li className="members-rights__content-list-item">
									не разглашать конфиденциальную информацию о <br />
									деятельности Союза;
								</li>
								<li className="members-rights__content-list-item">
									не совершать действия, заведомо направленные на <br />
									причинение вреда Союза;
								</li>
								<li className="members-rights__content-list-item">
									не совершать действия (бездействие), которые существенно <br />
									затрудняют или делают невозможным достижение целей, <br />
									ради которых создан Союз;
								</li>
								<li className="members-rights__content-list-item">
									выполнять иные обязанности, предусмотренные <br />
									действующим законодательством Российской Федерации, <br />
									настоящим Уставом, другими актами, принятыми органами <br />
									Союза в рамках их компетенций.
								</li>
							</ul>

							<div className="members-rights__content-link">
								<Link to="">
									Читать Устав СПОТ{' '}
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="23"
										height="23"
										viewBox="0 0 23 23"
										fill="none">
										<path
											d="M0.221749 21.2777C0.221805 21.8299 0.669611 22.2776 1.22195 22.2775L10.2229 22.2766C10.7752 22.2765 11.2229 21.8288 11.2229 21.2766C11.2228 20.7244 10.775 20.2767 10.2227 20.2768L2.22185 20.2776L2.22104 12.2784C2.22098 11.7262 1.77318 11.2786 1.22084 11.2786C0.668498 11.2787 0.220782 11.7264 0.220838 12.2786L0.221749 21.2777ZM21.0161 0.0690985L0.514601 20.5706L1.9291 21.9846L22.4306 1.48303L21.0161 0.0690985Z"
											fill="#0D2461"
										/>
									</svg>
								</Link>
								<div className="members-rights__content-info">
									<p>Информация размещена 20.01.2015 16:42</p>
									<p>Обновление информации 23.06.2021 13:44</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="members-form">
				<div className="members-form__container">
					<h2 className="members-form__head">ПОРЯДОК ПРИЕМА В ЧЛЕНЫ СОЮЗА</h2>

					<div className="members-form__content">
						<div className="members-form__content-left">
							<ol className="members-form__content-list">
								<li className="members-form__content-list-item">
									Прием нового члена Союза осуществляется на основании заявления на имя <br />
									Директора Союза путем принятия решения Советом о приеме нового члена.
								</li>
								<li className="members-form__content-list-item">
									После получения заявления, Директор Союза осуществляет проверку <br />
									полноты и достоверности сведений. По результатам проверки Директор <br />
									принимает решение о вынесении вопроса о приеме нового члена в Союз на <br />
									рассмотрение Совета.
								</li>
								<li className="members-form__content-list-item">
									Решение о приеме нового члена в Союз принимается Советом в порядке, <br />
									определенном Общим собранием Союза не позднее 6 (шести) месяцев с <br />
									момента подачи заявления. Совет имеет право отказать в приеме нового <br />
									члена в Союз без объяснения причин.
								</li>
								<li className="members-form__content-list-item">
									С момента принятия решения Советом новый член считается принятым в <br />
									Союз и обязан внести вступительный членский взнос, величина которого <br />
									определяется Общим собранием.
								</li>
								<li className="members-form__content-list-item">
									После внесения на счет Союза вступительного членского взноса, <br />
									ежегодного членского взноса новый член Союза приобретает <br />
									предусмотренные настоящим Уставом права и обязанности члена Союза.
								</li>
							</ol>
						</div>
						<div className="members-form__content-right">
							<div className="members-form__content-form">
								<form action="">
									<input type="text" name="ФИО" id="name" placeholder="ФИО" required />
									<input type="tel" name="phone" id="phone" placeholder="Телефон" required />
									<button className="members-form__content-form-btn" type="submit">
										Вступить в СПОТ
									</button>
								</form>
								<p className="members-form__content-form-copy">
									Я согласен(на) с Политикой обработки <br />
									персональных данных
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="members-reestr">
				<div className="members-reestr__container">
					<h2 className="members-reestr__head">реестр членов союза</h2>

					<div className="members-reestr__list">
						<div className="members-reestr__list-item">
							<h3 className="members-reestr__list-item-head">ООО «Перспектива»</h3>
							<p className="members-reestr__list-item-par">Директор — Яско Юрий Александрович</p>
							<p className="members-reestr__list-item-par">
								Сайт — <a href="http://etp-profit.ru">etp-profit.ru</a>
							</p>
						</div>
						<div className="members-reestr__list-item">
							<h3 className="members-reestr__list-item-head">ООО «ПРОМ-Консалтинг» </h3>
							<p className="members-reestr__list-item-par">
								Ген. директор — Стрелков Алексей Вячеславович{' '}
							</p>
							<p className="members-reestr__list-item-par">
								Сайт — <a href="http://promkonsalt.ru">promkonsalt.ru</a>
							</p>
						</div>
						<div className="members-reestr__list-item">
							<h3 className="members-reestr__list-item-head">ООО «Фабрикант.ру»</h3>
							<p className="members-reestr__list-item-par">
								Ген.директор — Габестро Сергей Владиленович{' '}
							</p>
							<p className="members-reestr__list-item-par">
								Сайт —<a href="http://fabrikant.ru">fabrikant.ru</a>
							</p>
						</div>
						<div className="members-reestr__list-item">
							<h3 className="members-reestr__list-item-head">ООО «ТендерСтандарт»</h3>
							<p className="members-reestr__list-item-par">
								Ген.директор — Корзун Лилия Евгеньевна
							</p>
							<p className="members-reestr__list-item-par">
								Сайт —<a href="http://tenderstandart.ru">tenderstandart.ru</a>
							</p>
						</div>
						<div className="members-reestr__list-item">
							<h3 className="members-reestr__list-item-head">ООО «ЕТП»</h3>
							<p className="members-reestr__list-item-par">
								Директор — Кидяев Александр Евгеньевич{' '}
							</p>
							<p className="members-reestr__list-item-par">
								Сайт —<a href="http://eurtp.ru">eurtp.ru</a>
							</p>
						</div>
						<div className="members-reestr__list-item">
							<h3 className="members-reestr__list-item-head">ООО «ЭТП»</h3>
							<p className="members-reestr__list-item-par">
								Директор — Андреев Александр Николаевич
							</p>
							<p className="members-reestr__list-item-par">
								Сайт —<a href="http://etp-bankrotstvo.ru/">etp-bankrotstvo.ru/</a>
							</p>
						</div>
						<div className="members-reestr__list-item">
							<h3 className="members-reestr__list-item-head">ООО «ПТП»</h3>
							<p className="members-reestr__list-item-par">
								Директор — Федотов Александр Владимирович
							</p>
							<p className="members-reestr__list-item-par">
								Сайт —<a href="http://vtb-center.ru">vtb-center.ru</a>
							</p>
						</div>
						<div className="members-reestr__list-item">
							<h3 className="members-reestr__list-item-head">ООО «Сервис-Консалт»</h3>
							<p className="members-reestr__list-item-par">
								Директор — Яркова Виктория Владимировна
							</p>
							<p className="members-reestr__list-item-par">
								Сайт —<a href="http://etpugra.ru">etpugra.ru</a>
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Members;
