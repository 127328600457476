import React from 'react';
import { Link } from 'react-router-dom';

import NewsCard from '../Components/NewsCard';
function News() {
	return (
		<div className="news">
			<div className="news__container">
				<h1 className="news__head">Новости и события</h1>

				<div className="news__news">
					<NewsCard
						head="Баннер с новостью"
						descHead="Единые правила игры против негативных тенденций"
						desc="В пятницу, 18 февраля, прошло заседание Экспертного совета по развитию конкуренции в сфере банкротства Комитета ГД."
						background="#D2F6FE"
					/>
					<NewsCard
						background="#D2F6FE"
						head="Баннер с новостью"
						descHead="Единые правила игры против негативных тенденций"
						desc="В пятницу, 18 февраля, прошло заседание Экспертного совета по развитию конкуренции в сфере банкротства Комитета ГД."
					/>
					<NewsCard
						background="#D2F6FE"
						head="Баннер с новостью"
						descHead="Единые правила игры против негативных тенденций"
						desc="В пятницу, 18 февраля, прошло заседание Экспертного совета по развитию конкуренции в сфере банкротства Комитета ГД."
					/>
					<NewsCard
						background="#D2F6FE"
						head="Баннер с новостью"
						descHead="Единые правила игры против негативных тенденций"
						desc="В пятницу, 18 февраля, прошло заседание Экспертного совета по развитию конкуренции в сфере банкротства Комитета ГД."
					/>
					<NewsCard
						background="#D2F6FE"
						head="Баннер с новостью"
						descHead="Единые правила игры против негативных тенденций"
						desc="В пятницу, 18 февраля, прошло заседание Экспертного совета по развитию конкуренции в сфере банкротства Комитета ГД."
					/>
					<NewsCard
						background="#D2F6FE"
						head="Баннер с новостью"
						descHead="Единые правила игры против негативных тенденций"
						desc="В пятницу, 18 февраля, прошло заседание Экспертного совета по развитию конкуренции в сфере банкротства Комитета ГД."
					/>
					<NewsCard
						background="#D2F6FE"
						head="Баннер с новостью"
						descHead="Единые правила игры против негативных тенденций"
						desc="В пятницу, 18 февраля, прошло заседание Экспертного совета по развитию конкуренции в сфере банкротства Комитета ГД."
					/>
					<NewsCard
						background="#D2F6FE"
						head="Баннер с новостью"
						descHead="Единые правила игры против негативных тенденций"
						desc="В пятницу, 18 февраля, прошло заседание Экспертного совета по развитию конкуренции в сфере банкротства Комитета ГД."
					/>
					<NewsCard
						background="#D2F6FE"
						head="Баннер с новостью"
						descHead="Единые правила игры против негативных тенденций"
						desc="В пятницу, 18 февраля, прошло заседание Экспертного совета по развитию конкуренции в сфере банкротства Комитета ГД."
					/>
				</div>

				<div className="news__more">
					<Link to="/news">
						Читать предыдущие новости{' '}
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="23"
							height="23"
							viewBox="0 0 23 23"
							fill="none">
							<path
								d="M0.0603398 21.0322C0.0484623 21.5843 0.48649 22.0415 1.0387 22.0534L10.0375 22.247C10.5897 22.2589 11.047 21.8209 11.0589 21.2688C11.0708 20.7167 10.6328 20.2595 10.0805 20.2476L2.08159 20.0756L2.25364 12.0782C2.26552 11.5261 1.82749 11.0689 1.27528 11.057C0.723064 11.0452 0.265778 11.4831 0.253901 12.0352L0.0603398 21.0322ZM21.3082 0.277918L0.368403 20.3316L1.75202 21.7758L22.6918 1.72208L21.3082 0.277918Z"
								fill="#0D2461"
							/>
						</svg>
					</Link>
				</div>
			</div>
		</div>
	);
}

export default News;
