import React from 'react';
import { Link } from 'react-router-dom';
import { CloseOutlined } from '@ant-design/icons';

import Style from './MobileHeader.module.scss';

function MobileHeader({ isMenuOpen, onTapOpen, onTapClose }) {
	return (
		<header className={Style.header}>
			<div className={Style.container}>
				<Link to="/">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="33"
						height="36"
						viewBox="0 0 33 36"
						fill="none">
						<path
							d="M17.806 35.6733C12.6534 35.6733 8.39754 33.9582 5.03852 30.5281C1.67951 27.098 0 22.8675 0 17.8366C0 12.7731 1.67951 8.54265 5.03852 5.14519C8.39754 1.71506 12.6534 0 17.806 0C20.9042 0 23.7577 0.735027 26.3666 2.20508C29.0082 3.64247 31.0627 5.60254 32.5303 8.08529L26.7091 11.4664C25.8612 9.93103 24.6545 8.73865 23.0892 7.88929C21.5238 7.00725 19.7628 6.56624 17.806 6.56624C14.4796 6.56624 11.7892 7.61161 9.73462 9.70235C7.71269 11.7931 6.70173 14.5045 6.70173 17.8366C6.70173 21.1361 7.71269 23.8312 9.73462 25.9219C11.7892 28.0127 14.4796 29.0581 17.806 29.0581C19.7628 29.0581 21.5238 28.6334 23.0892 27.784C24.6871 26.902 25.8938 25.7096 26.7091 24.2069L32.5303 27.588C31.0627 30.0708 29.0245 32.0472 26.4156 33.5172C23.8066 34.9546 20.9368 35.6733 17.806 35.6733Z"
							fill="url(#paint0_linear_793_1612)"
						/>
						<path
							d="M24.2156 24.7143C22.4437 26.4671 20.2872 27.3435 17.7463 27.3435C15.2053 27.3435 13.0489 26.4671 11.2769 24.7143C9.52167 22.9447 8.64404 20.7829 8.64404 18.2288C8.64404 15.6746 9.52167 13.5212 11.2769 11.7683C13.0489 9.99878 15.2053 9.11401 17.7463 9.11401C20.2872 9.11401 22.4437 9.99878 24.2156 11.7683C25.9876 13.5212 26.8736 15.6746 26.8736 18.2288C26.8736 20.7829 25.9876 22.9447 24.2156 24.7143ZM13.7092 22.3605C14.7958 23.4289 16.1415 23.9631 17.7463 23.9631C19.3511 23.9631 20.6968 23.4289 21.7833 22.3605C22.8699 21.2754 23.4132 19.8981 23.4132 18.2288C23.4132 16.5594 22.8699 15.1822 21.7833 14.0971C20.6968 13.012 19.3511 12.4694 17.7463 12.4694C16.1415 12.4694 14.7958 13.012 13.7092 14.0971C12.6226 15.1822 12.0793 16.5594 12.0793 18.2288C12.0793 19.8981 12.6226 21.2754 13.7092 22.3605Z"
							fill="url(#paint1_linear_793_1612)"
						/>
						<path
							d="M26.6374 2.43584V19.6439H22.0631V7.3861C22.0631 7.3861 20.43 6.60035 17.7584 6.60035C15.0868 6.60035 13.4537 7.3861 13.4537 7.3861V19.6439H8.80078V2.20012C8.80078 2.20012 10.9223 1.44384e-10 17.6012 0C24.2802 -1.44384e-10 26.6374 2.43584 26.6374 2.43584Z"
							fill="url(#paint2_linear_793_1612)"
						/>
						<path
							d="M26.716 15.244V19.8431H20.1543V35.6737H15.248V19.8431H8.80078V15.244H26.716Z"
							fill="url(#paint3_linear_793_1612)"
						/>
						<defs>
							<linearGradient
								id="paint0_linear_793_1612"
								x1="-4.40023"
								y1="2.82872"
								x2="18.2295"
								y2="34.2589"
								gradientUnits="userSpaceOnUse">
								<stop stopColor="#0956F5" />
								<stop offset="0.453125" stopColor="#00AFFF" />
								<stop offset="1" stopColor="#0D2461" />
							</linearGradient>
							<linearGradient
								id="paint1_linear_793_1612"
								x1="6.17821"
								y1="10.5595"
								x2="17.3669"
								y2="27.6008"
								gradientUnits="userSpaceOnUse">
								<stop stopColor="#0956F5" />
								<stop offset="0.453125" stopColor="#00AFFF" />
								<stop offset="1" stopColor="#0D2461" />
							</linearGradient>
							<linearGradient
								id="paint2_linear_793_1612"
								x1="6.38809"
								y1="1.55766"
								x2="18.8662"
								y2="18.8144"
								gradientUnits="userSpaceOnUse">
								<stop stopColor="#0956F5" />
								<stop offset="0.453125" stopColor="#00AFFF" />
								<stop offset="1" stopColor="#0D2461" />
							</linearGradient>
							<linearGradient
								id="paint3_linear_793_1612"
								x1="6.37747"
								y1="16.864"
								x2="19.4899"
								y2="34.3773"
								gradientUnits="userSpaceOnUse">
								<stop stopColor="#0956F5" />
								<stop offset="0.453125" stopColor="#00AFFF" />
								<stop offset="1" stopColor="#0D2461" />
							</linearGradient>
						</defs>
					</svg>
				</Link>

				<button className={Style.button}>
					{!isMenuOpen ? (
						<svg
							onClick={onTapOpen}
							xmlns="http://www.w3.org/2000/svg"
							width="21"
							height="13"
							viewBox="0 0 21 13"
							fill="none">
							<path d="M0 0H20.6117V2.58333H0V0Z" fill="#0D2461" />
							<path d="M0 5.16699H20.6117V7.75033H0V5.16699Z" fill="#0D2461" />
							<path d="M0 10.3335H20.6117V12.9168H0V10.3335Z" fill="#0D2461" />
						</svg>
					) : (
						<CloseOutlined onClick={onTapClose} />
					)}
				</button>
			</div>
		</header>
	);
}

export default MobileHeader;
