import React from 'react';
import { Link } from 'react-router-dom';

import Style from './MobileMenu.module.scss';

function MobileMenu(props) {
	return (
		<div className={props.isMenuOpen ? Style.menu : Style.menu_close}>
			<div className={Style.container}>
				<nav className={Style.nav}>
					<ul className={Style.list}>
						<li className={Style.item}>
							<Link to="/about" onClick={props.onTapClose}>
								О союзе
							</Link>
						</li>
						<li className={Style.item}>
							<Link to="/news" onClick={props.onTapClose}>
								Новости
							</Link>
						</li>
						<li className={Style.item}>
							<Link to="/members" onClick={props.onTapClose}>
								Членам СРО
							</Link>
						</li>
						<li className={Style.item}>
							<Link to="/docs" onClick={props.onTapClose}>
								Документация
							</Link>
						</li>
						<li className={Style.item}>
							<Link to="/contacts" onClick={props.onTapClose}>
								Контакты
							</Link>
						</li>
					</ul>
				</nav>
			</div>
		</div>
	);
}

export default MobileMenu;
