import React from 'react';
import Style from './NewsCard.module.scss';
import { Link } from 'react-router-dom';

function NewsCard(props) {
	return (
		<div className={Style.newsCard}>
			<div className={Style.banner}>
				<h3 className={Style.banner__head}>{props.head}</h3>
			</div>
			<div className={Style.content} style={{ background: props.background }}>
				<div className={Style.content__container}>
					<h3 className={Style.content__head}>{props.descHead}</h3>
					<p className={Style.content__desc}>{props.desc}</p>
					<Link to="/newspageone" className={Style.content__btn}>
						Читать далее
					</Link>
				</div>
			</div>
		</div>
	);
}

export default NewsCard;
