import React from 'react';

import Style from './MemberCard.module.scss';

function MemberCard(props) {
	return (
		<div className={Style.memberCard}>
			<img src={props.img} alt="ЛОГО БЕЗ ФОНА В ЧБ" />
		</div>
	);
}

export default MemberCard;
