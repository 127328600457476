import React from 'react';

function About() {
	return (
		<div className="about">
			<div className="about-banner">
				<div className="about-banner__container">
					<div className="about-banner__head">
						<h1 className="about-banner__head-top">
							<span>СПОТ —</span> ЭТО высокие
						</h1>
						<h2 className="about-banner__head-bot">стандарты качества</h2>
					</div>

					<div className="about-banner__desc">
						<div className="about-banner__desc-stick"></div>
						<p className="about-banner__desc-desc">
							Союз профессиональных организаторов торгов является некоммерческой <br />
							организацией, основанной на членстве, созданной в целях защиты общих, <br />в том
							числе профессиональных, интересов членов, а также для <br />
							регулирования деятельности своих членов.
						</p>
					</div>
				</div>
			</div>

			<div className="about-goals">
				<div className="about-goals__container">
					<h2 className="about-goals__head">ЦЕЛИ ДЕЯТЕЛЬНОСТИ СОЮЗА</h2>

					<div className="about-goals__list">
						<div className="about-goals__list-item">
							<h3 className="about-goals__list-item-head">
								Регулирование и обеспечение <br />
								деятельности членов СПОТ
							</h3>
							<p className="about-goals__list-item-desc">
								Регулирование и обеспечение деятельности своих членов <br />
								при осуществлении ими профессиональной деятельности <br />в качестве организ аторов
								торгов, а также представление <br />и защита их интересов
							</p>
						</div>
						<div className="about-goals__list-item">
							<h3 className="about-goals__list-item-head">
								Координация деятельности <br />
								членов СПОТ
							</h3>
							<p className="about-goals__list-item-desc">
								Координация деятельности членов Союза для решения <br />
								общих вопросов при осуществлении ими <br />
								профессиональной деятельности в качестве <br />
								организаторов торгов на территории города Москвы
							</p>
						</div>
						<div className="about-goals__list-item">
							<h3 className="about-goals__list-item-head">
								Распространение информации <br />о деятельности членов СПОТ
							</h3>
							<p className="about-goals__list-item-desc">
								Распространение информации о деятельности своих <br />
								членов при осуществлении ими профессиональной <br />
								деятельности в качестве организаторов торгов, как на <br />
								территории Российской Федерации, так и за рубежом
							</p>
						</div>
						<div className="about-goals__list-item">
							<h3 className="about-goals__list-item-head">
								Представление и защита определенных <br />
								интересов членов СПОТ
							</h3>
							<p className="about-goals__list-item-desc">
								Представление и защита общих имущественных <br />и неимущественных интересов членов
								Союза <br />
								профессиональных организаторов торгов
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default About;
