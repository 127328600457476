import React from 'react';

function Docs() {
	return (
		<div className="doc">
			<div className="doc__container">
				<h1 className="doc__head">перечень документов </h1>

				<div className="doc__list">
					<div className="doc__list-item">
						<h3 className="doc__list-item-head">
							<a href="../Static/Устав СПОТ.docx" download target="_blank">
								Устав СПОТ
							</a>
						</h3>
						<p className="doc__list-item-desc">Информация размещена 20.01.2015 16:42</p>
						<p className="doc__list-item-desc">Обновление информации 23.06.2021 13:44</p>
					</div>
					<div className="doc__list-item">
						<h3 className="doc__list-item-head">
							<a
								href="../Static/Баланс 2022 год с документами подтверждающими отправку в ФНС.pdf"
								target="_blank">
								Положение о взносах и комфонде СПОТ
							</a>
						</h3>
						<p className="doc__list-item-desc">Информация размещена 20.01.2015 16:42</p>
						<p className="doc__list-item-desc">Обновление информации 23.06.2021 13:44</p>
					</div>
					<div className="doc__list-item">
						<h3 className="doc__list-item-head">
							<a href="../Static/Свидетельство о регистрации.pdf">Свидетельства о регистрации</a>
						</h3>
						<p className="doc__list-item-desc">Информация размещена 20.01.2015 16:42</p>
						<p className="doc__list-item-desc">Обновление информации 23.06.2021 13:44</p>
					</div>
					<div className="doc__list-item">
						<h3 className="doc__list-item-head">
							<a href="../Static/О предоставлении отчетности.docx">
								Положение о предоставлении отчетности членами СПОТ
							</a>
						</h3>
						<p className="doc__list-item-desc">Информация размещена 20.01.2015 16:42</p>
						<p className="doc__list-item-desc">Обновление информации 23.06.2021 13:44</p>
					</div>
					<div className="doc__list-item">
						<h3 className="doc__list-item-head">
							<a href="../Static/Правила приема членов СПОТ.docx">Правила приёма в члены СПОТ</a>
						</h3>
						<p className="doc__list-item-desc">Информация размещена 20.01.2015 16:42</p>
						<p className="doc__list-item-desc">Обновление информации 23.06.2021 13:44</p>
					</div>
					<div className="doc__list-item">
						<h3 className="doc__list-item-head">
							<a href="../Static/Об Аккредитации.pdf">Положение об Аккредитации</a>
						</h3>
						<p className="doc__list-item-desc">Информация размещена 20.01.2015 16:42</p>
						<p className="doc__list-item-desc">Обновление информации 23.06.2021 13:44</p>
					</div>
					<div className="doc__list-item">
						<h3 className="doc__list-item-head">
							<a href="../Static/Условия членства в СПОТ.docx">Условия членства в СПОТ</a>
						</h3>
						<p className="doc__list-item-desc">Информация размещена 20.01.2015 16:42</p>
						<p className="doc__list-item-desc">Обновление информации 23.06.2021 13:44</p>
					</div>
					<div className="doc__list-item">
						<h3 className="doc__list-item-head">
							<a href="../Static/Требования к раскрытию информации СПОТ.docx">
								Требования к раскрытию информации СПОТ
							</a>
						</h3>
						<p className="doc__list-item-desc">Информация размещена 20.01.2015 16:42</p>
						<p className="doc__list-item-desc">Обновление информации 23.06.2021 13:44</p>
					</div>
					<div className="doc__list-item">
						<h3 className="doc__list-item-head">
							<a href="../Static/Стандарты и Правила профессиональной деятельности СПОТ.docx">
								Стандарты и Правила профессиональной <br />
								деятельности СПОТ
							</a>
						</h3>
						<p className="doc__list-item-desc">Информация размещена 20.01.2015 16:42</p>
						<p className="doc__list-item-desc">Обновление информации 23.06.2021 13:44</p>
					</div>
					<div className="doc__list-item">
						<h3 className="doc__list-item-head">
							<a href="../Static/О контрольном комитете и порядке проведения проверки деятельности членов.docx">
								О контрольном комитете и порядке проведения <br />
								проверки деятельности членов
							</a>
						</h3>
						<p className="doc__list-item-desc">Информация размещена 20.01.2015 16:42</p>
						<p className="doc__list-item-desc">Обновление информации 23.06.2021 13:44</p>
					</div>
					<div className="doc__list-item">
						<h3 className="doc__list-item-head">
							<a href="../Static/О дисциплинарной комиссии и дисциплинарной ответственности членов СПОТ.docx">
								О дисциплинарной комиссии и дисциплинарной <br />
								ответственности членов СПОТ
							</a>
						</h3>
						<p className="doc__list-item-desc">Информация размещена 20.01.2015 16:42</p>
						<p className="doc__list-item-desc">Обновление информации 23.06.2021 13:44</p>
					</div>
					<div className="doc__list-item">
						<h3 className="doc__list-item-head">
							<a href="../Static/АЗ СПОТ 2022.pdf">
								Информация о порядке осуществления <br />
								аттестации членов Союза
							</a>
						</h3>
						<p className="doc__list-item-desc">Информация размещена 20.01.2015 16:42</p>
						<p className="doc__list-item-desc">Обновление информации 23.06.2021 13:44</p>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Docs;
