import React from 'react';

import { Link } from 'react-router-dom';

import Style from './Footer.module.scss';

function Footer() {
	return (
		<footer className={Style.footer}>
			<div className={Style.footer__container}>
				<div className={Style.footer__content}>
					<div className={Style.footer__info}>
						<h2 className={Style.footer__info_head}>КОНТАКТЫ</h2>
						<div className={Style.footer__info_item}>
							<h3 className={Style.footer__info_item_head}>Адрес местонахождения</h3>
							<div className={Style.footer__info_item_desc}>
								<p>121069, Москва г, ул Поварская,</p>
								<p>д. 10, стр. 1, офис 400, помещ. 27/1</p>
							</div>
						</div>
						<div className={Style.footer__info_item}>
							<h3 className={Style.footer__info_item_head}>Контакты для связи</h3>

							<div className={Style.footer__info_item_desc}>
								<a href="tel:+79889443196">+7 988 944-31-96</a>
								<a href="mailto:support@souzspot.ru">support@souzspot.ru</a>
							</div>
						</div>
					</div>
					<nav className={Style.footer__nav}>
						<div className={Style.footer__logo}>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="103"
								height="69"
								viewBox="0 0 103 69"
								fill="none">
								<path
									d="M51.3983 49.4087C44.2617 49.4087 38.3672 47.0333 33.7149 42.2825C29.0625 37.5316 26.7363 31.6723 26.7363 24.7044C26.7363 17.6912 29.0625 11.8319 33.7149 7.12626C38.3672 2.37542 44.2617 0 51.3983 0C55.6893 0 59.6415 1.01804 63.255 3.05411C66.9137 5.04494 69.7593 7.7597 71.7918 11.1984L63.7293 15.8814C62.5549 13.7548 60.8837 12.1033 58.7156 10.9269C56.5475 9.70528 54.1084 9.09446 51.3983 9.09446C46.7911 9.09446 43.0647 10.5423 40.2191 13.4381C37.4187 16.3338 36.0184 20.0893 36.0184 24.7044C36.0184 29.2742 37.4187 33.007 40.2191 35.9028C43.0647 38.7985 46.7911 40.2464 51.3983 40.2464C54.1084 40.2464 56.5475 39.6582 58.7156 38.4818C60.9288 37.2601 62.6001 35.6087 63.7293 33.5273L71.7918 38.2103C69.7593 41.649 66.9362 44.3864 63.3228 46.4225C59.7093 48.4133 55.7345 49.4087 51.3983 49.4087Z"
									fill="url(#paint0_linear_803_902)"
								/>
								<path
									d="M60.2761 34.2309C57.8219 36.6587 54.8351 37.8725 51.3159 37.8725C47.7966 37.8725 44.8098 36.6587 42.3556 34.2309C39.9245 31.7801 38.709 28.7858 38.709 25.2483C38.709 21.7107 39.9245 18.7281 42.3556 16.3003C44.8098 13.8495 47.7966 12.624 51.3159 12.624C54.8351 12.624 57.8219 13.8495 60.2761 16.3003C62.7304 18.7281 63.9575 21.7107 63.9575 25.2483C63.9575 28.7858 62.7304 31.7801 60.2761 34.2309ZM45.7244 30.9708C47.2293 32.4506 49.0932 33.1905 51.3159 33.1905C53.5386 33.1905 55.4024 32.4506 56.9074 30.9708C58.4123 29.4679 59.1648 27.5604 59.1648 25.2483C59.1648 22.9361 58.4123 21.0286 56.9074 19.5257C55.4024 18.0229 53.5386 17.2714 51.3159 17.2714C49.0932 17.2714 47.2293 18.0229 45.7244 19.5257C44.2194 21.0286 43.467 22.9361 43.467 25.2483C43.467 27.5604 44.2194 29.4679 45.7244 30.9708Z"
									fill="url(#paint1_linear_803_902)"
								/>
								<path
									d="M63.6301 3.37372V27.2074H57.2945V10.23C57.2945 10.23 55.0326 9.1417 51.3324 9.1417C47.6322 9.1417 45.3703 10.23 45.3703 10.23V27.2074H38.9258V3.04723C38.9258 3.04723 41.8642 1.99976e-10 51.1147 0C60.3652 -1.99976e-10 63.6301 3.37372 63.6301 3.37372Z"
									fill="url(#paint2_linear_803_902)"
								/>
								<path
									d="M63.739 21.1133V27.4831H54.6508V49.409H47.8554V27.4831H38.9258V21.1133H63.739Z"
									fill="url(#paint3_linear_803_902)"
								/>
								<path
									d="M4.45213 58.8477H3.14431C3.13504 58.7395 3.11031 58.6414 3.07011 58.5532C3.03147 58.4651 2.97736 58.3894 2.9078 58.326C2.83978 58.2611 2.75707 58.2116 2.65968 58.1776C2.56229 58.142 2.45176 58.1243 2.32809 58.1243C2.11167 58.1243 1.92848 58.1768 1.77853 58.2819C1.63013 58.3871 1.51728 58.5378 1.43998 58.7341C1.36424 58.9304 1.32636 59.1662 1.32636 59.4413C1.32636 59.732 1.36501 59.9754 1.4423 60.1718C1.52114 60.3666 1.63477 60.5134 1.78317 60.6123C1.93157 60.7097 2.11012 60.7584 2.31882 60.7584C2.43785 60.7584 2.54451 60.7437 2.63881 60.7144C2.73311 60.6835 2.81504 60.6394 2.88461 60.5822C2.95417 60.525 3.0106 60.4562 3.05388 60.3758C3.09871 60.2939 3.12886 60.2019 3.14431 60.0999L4.45213 60.1092C4.43667 60.3101 4.38024 60.515 4.28285 60.7236C4.18546 60.9308 4.04711 61.1225 3.86779 61.2987C3.69001 61.4734 3.46972 61.6141 3.20692 61.7207C2.94412 61.8274 2.63881 61.8807 2.29099 61.8807C1.85505 61.8807 1.46395 61.7872 1.11767 61.6002C0.772938 61.4131 0.500091 61.1379 0.299127 60.7747C0.0997092 60.4114 0 59.9669 0 59.4413C0 58.9127 0.102028 58.4674 0.306084 58.1057C0.51014 57.7424 0.785307 57.468 1.13158 57.2825C1.47786 57.0955 1.86433 57.0019 2.29099 57.0019C2.59089 57.0019 2.86683 57.0429 3.11881 57.1248C3.37079 57.2068 3.59185 57.3266 3.78199 57.4843C3.97213 57.6404 4.12517 57.8329 4.24111 58.0616C4.35706 58.2904 4.42739 58.5525 4.45213 58.8477Z"
									fill="white"
								/>
								<path
									d="M9.63758 59.4413C9.63758 59.97 9.53478 60.416 9.32918 60.7793C9.12358 61.141 8.84609 61.4154 8.49672 61.6025C8.14735 61.788 7.75779 61.8807 7.32804 61.8807C6.89519 61.8807 6.50409 61.7872 6.15472 61.6002C5.80689 61.4116 5.53018 61.1364 5.32458 60.7747C5.12053 60.4114 5.0185 59.9669 5.0185 59.4413C5.0185 58.9127 5.12053 58.4674 5.32458 58.1057C5.53018 57.7424 5.80689 57.468 6.15472 57.2825C6.50409 57.0955 6.89519 57.0019 7.32804 57.0019C7.75779 57.0019 8.14735 57.0955 8.49672 57.2825C8.84609 57.468 9.12358 57.7424 9.32918 58.1057C9.53478 58.4674 9.63758 58.9127 9.63758 59.4413ZM8.31122 59.4413C8.31122 59.1569 8.27334 58.9173 8.19759 58.7225C8.12339 58.5262 8.01286 58.3778 7.866 58.2773C7.72069 58.1753 7.54137 58.1243 7.32804 58.1243C7.11471 58.1243 6.93461 58.1753 6.78775 58.2773C6.64244 58.3778 6.53191 58.5262 6.45616 58.7225C6.38196 58.9173 6.34486 59.1569 6.34486 59.4413C6.34486 59.7258 6.38196 59.9662 6.45616 60.1625C6.53191 60.3573 6.64244 60.5057 6.78775 60.6077C6.93461 60.7082 7.11471 60.7584 7.32804 60.7584C7.54137 60.7584 7.72069 60.7082 7.866 60.6077C8.01286 60.5057 8.12339 60.3573 8.19759 60.1625C8.27334 59.9662 8.31122 59.7258 8.31122 59.4413Z"
									fill="white"
								/>
								<path
									d="M11.5234 57.0669V61.8158H10.2341V57.0669H11.5234ZM12.5344 59.0703V60.035H10.9761V59.0703H12.5344ZM16.7361 59.4413C16.7361 59.97 16.6333 60.416 16.4277 60.7793C16.2221 61.141 15.9446 61.4154 15.5952 61.6025C15.2458 61.788 14.8563 61.8807 14.4265 61.8807C13.9937 61.8807 13.6026 61.7872 13.2532 61.6002C12.9054 61.4116 12.6287 61.1364 12.4231 60.7747C12.219 60.4114 12.117 59.9669 12.117 59.4413C12.117 58.9127 12.219 58.4674 12.4231 58.1057C12.6287 57.7424 12.9054 57.468 13.2532 57.2825C13.6026 57.0955 13.9937 57.0019 14.4265 57.0019C14.8563 57.0019 15.2458 57.0955 15.5952 57.2825C15.9446 57.468 16.2221 57.7424 16.4277 58.1057C16.6333 58.4674 16.7361 58.9127 16.7361 59.4413ZM15.4097 59.4413C15.4097 59.1569 15.3718 58.9173 15.2961 58.7225C15.2219 58.5262 15.1113 58.3778 14.9645 58.2773C14.8192 58.1753 14.6398 58.1243 14.4265 58.1243C14.2132 58.1243 14.0331 58.1753 13.8862 58.2773C13.7409 58.3778 13.6304 58.5262 13.5546 58.7225C13.4804 58.9173 13.4433 59.1569 13.4433 59.4413C13.4433 59.7258 13.4804 59.9662 13.5546 60.1625C13.6304 60.3573 13.7409 60.5057 13.8862 60.6077C14.0331 60.7082 14.2132 60.7584 14.4265 60.7584C14.6398 60.7584 14.8192 60.7082 14.9645 60.6077C15.1113 60.5057 15.2219 60.3573 15.2961 60.1625C15.3718 59.9662 15.4097 59.7258 15.4097 59.4413Z"
									fill="white"
								/>
								<path
									d="M19.1957 61.8807C18.8216 61.8807 18.4893 61.8166 18.1986 61.6883C17.9096 61.5584 17.6823 61.3799 17.5169 61.1526C17.3515 60.9254 17.268 60.6641 17.2665 60.3689H18.565C18.5666 60.4601 18.5944 60.542 18.6485 60.6147C18.7041 60.6858 18.7799 60.7414 18.8757 60.7816C18.9716 60.8218 19.0813 60.8419 19.205 60.8419C19.324 60.8419 19.4292 60.821 19.5204 60.7793C19.6116 60.736 19.6827 60.6765 19.7337 60.6008C19.7847 60.525 19.8095 60.4384 19.8079 60.341C19.8095 60.2452 19.7801 60.1602 19.7198 60.086C19.661 60.0118 19.5783 59.9538 19.4717 59.9121C19.365 59.8703 19.2421 59.8495 19.103 59.8495H18.6207V58.959H19.103C19.2313 58.959 19.3441 58.9382 19.4415 58.8964C19.5405 58.8547 19.617 58.7967 19.6711 58.7225C19.7267 58.6483 19.7538 58.5633 19.7523 58.4674C19.7538 58.3747 19.7314 58.2928 19.685 58.2216C19.6386 58.1505 19.5737 58.0949 19.4902 58.0547C19.4083 58.0145 19.3132 57.9944 19.205 57.9944C19.0875 57.9944 18.9824 58.0153 18.8897 58.057C18.7984 58.0987 18.7266 58.1567 18.674 58.2309C18.6214 58.3051 18.5944 58.3901 18.5928 58.486H17.3592C17.3608 58.1954 17.4404 57.9388 17.5981 57.7161C17.7573 57.4935 17.9753 57.3189 18.252 57.1921C18.5287 57.0653 18.8464 57.0019 19.205 57.0019C19.5559 57.0019 19.8659 57.0615 20.1349 57.1805C20.4054 57.2995 20.6164 57.4634 20.7679 57.6721C20.9209 57.8792 20.9967 58.1165 20.9951 58.384C20.9982 58.6499 20.907 58.8678 20.7215 59.0379C20.5376 59.2079 20.3041 59.3084 20.0212 59.3393V59.3764C20.4046 59.4182 20.6929 59.5356 20.8862 59.7289C21.0794 59.9206 21.1745 60.1617 21.1714 60.4523C21.1729 60.7306 21.0894 60.9772 20.9209 61.192C20.754 61.4069 20.5213 61.5754 20.223 61.6976C19.9262 61.8197 19.5838 61.8807 19.1957 61.8807Z"
									fill="white"
								/>
								<path
									d="M27.398 57.0669V61.8158H26.1273V58.0872H24.5226V61.8158H23.2519V57.0669H27.398Z"
									fill="white"
								/>
								<path
									d="M28.2229 61.8158V57.0669H30.2727C30.6252 57.0669 30.9336 57.1364 31.1979 57.2756C31.4623 57.4147 31.6679 57.6103 31.8147 57.8622C31.9616 58.1142 32.035 58.4087 32.035 58.7457C32.035 59.0858 31.9593 59.3803 31.8078 59.6292C31.6578 59.8781 31.4468 60.0697 31.1747 60.2042C30.9042 60.3387 30.5881 60.406 30.2263 60.406H29.002V59.4042H29.9666C30.1181 59.4042 30.2472 59.378 30.3539 59.3254C30.4621 59.2713 30.5448 59.1948 30.602 59.0958C30.6607 58.9969 30.6901 58.8802 30.6901 58.7457C30.6901 58.6097 30.6607 58.4937 30.602 58.3979C30.5448 58.3005 30.4621 58.2263 30.3539 58.1753C30.2472 58.1227 30.1181 58.0964 29.9666 58.0964H29.5121V61.8158H28.2229Z"
									fill="white"
								/>
								<path
									d="M37.1086 59.4413C37.1086 59.97 37.0058 60.416 36.8002 60.7793C36.5946 61.141 36.3171 61.4154 35.9677 61.6025C35.6184 61.788 35.2288 61.8807 34.7991 61.8807C34.3662 61.8807 33.9751 61.7872 33.6257 61.6002C33.2779 61.4116 33.0012 61.1364 32.7956 60.7747C32.5915 60.4114 32.4895 59.9669 32.4895 59.4413C32.4895 58.9127 32.5915 58.4674 32.7956 58.1057C33.0012 57.7424 33.2779 57.468 33.6257 57.2825C33.9751 57.0955 34.3662 57.0019 34.7991 57.0019C35.2288 57.0019 35.6184 57.0955 35.9677 57.2825C36.3171 57.468 36.5946 57.7424 36.8002 58.1057C37.0058 58.4674 37.1086 58.9127 37.1086 59.4413ZM35.7822 59.4413C35.7822 59.1569 35.7444 58.9173 35.6686 58.7225C35.5944 58.5262 35.4839 58.3778 35.337 58.2773C35.1917 58.1753 35.0124 58.1243 34.7991 58.1243C34.5857 58.1243 34.4056 58.1753 34.2588 58.2773C34.1134 58.3778 34.0029 58.5262 33.9272 58.7225C33.853 58.9173 33.8159 59.1569 33.8159 59.4413C33.8159 59.7258 33.853 59.9662 33.9272 60.1625C34.0029 60.3573 34.1134 60.5057 34.2588 60.6077C34.4056 60.7082 34.5857 60.7584 34.7991 60.7584C35.0124 60.7584 35.1917 60.7082 35.337 60.6077C35.4839 60.5057 35.5944 60.3573 35.6686 60.1625C35.7444 59.9662 35.7822 59.7258 35.7822 59.4413Z"
									fill="white"
								/>
								<path
									d="M39.7457 57.5399H41.0164C41.4152 57.5399 41.7684 57.6211 42.0761 57.7834C42.3853 57.9457 42.6272 58.176 42.8019 58.4744C42.9781 58.7728 43.0662 59.126 43.0662 59.5341C43.0662 59.9206 42.9781 60.2552 42.8019 60.5381C42.6272 60.821 42.3853 61.0398 42.0761 61.1944C41.7684 61.349 41.4152 61.4262 41.0164 61.4262H39.7457C39.3437 61.4262 38.9874 61.3497 38.6767 61.1967C38.3675 61.0436 38.1248 60.8249 37.9486 60.5405C37.7739 60.256 37.6866 59.9175 37.6866 59.5248C37.6866 59.1167 37.7754 58.7642 37.9532 58.4674C38.1325 58.1706 38.3768 57.9418 38.686 57.7811C38.9967 57.6203 39.3499 57.5399 39.7457 57.5399ZM39.7457 58.5973C39.5849 58.5973 39.4365 58.6274 39.3005 58.6877C39.166 58.748 39.0578 58.8462 38.9758 58.9822C38.8954 59.1183 38.8552 59.2991 38.8552 59.5248C38.8552 59.732 38.897 59.8974 38.9805 60.021C39.0655 60.1447 39.1752 60.2336 39.3097 60.2877C39.4458 60.3418 39.5911 60.3689 39.7457 60.3689H41.0257C41.1833 60.3689 41.3271 60.341 41.457 60.2854C41.5884 60.2282 41.6927 60.1385 41.77 60.0164C41.8488 59.8927 41.8883 59.732 41.8883 59.5341C41.8883 59.3084 41.8488 59.1268 41.77 58.9892C41.6927 58.8516 41.5884 58.7519 41.457 58.69C41.3271 58.6282 41.1833 58.5973 41.0257 58.5973H39.7457ZM40.9793 56.9834V62.0106H39.7735V56.9834H40.9793Z"
									fill="white"
								/>
								<path
									d="M43.661 61.8158V57.0669H47.0836V58.1057H44.9503V58.9219H46.9073V59.9608H44.9503V60.777H47.0743V61.8158H43.661Z"
									fill="white"
								/>
								<path
									d="M52.1565 58.8477H50.8487C50.8395 58.7395 50.8147 58.6414 50.7745 58.5532C50.7359 58.4651 50.6818 58.3894 50.6122 58.326C50.5442 58.2611 50.4615 58.2116 50.3641 58.1776C50.2667 58.142 50.1562 58.1243 50.0325 58.1243C49.8161 58.1243 49.6329 58.1768 49.483 58.2819C49.3345 58.3871 49.2217 58.5378 49.1444 58.7341C49.0687 58.9304 49.0308 59.1662 49.0308 59.4413C49.0308 59.732 49.0694 59.9754 49.1467 60.1718C49.2256 60.3666 49.3392 60.5134 49.4876 60.6123C49.636 60.7097 49.8145 60.7584 50.0232 60.7584C50.1423 60.7584 50.2489 60.7437 50.3432 60.7144C50.4375 60.6835 50.5195 60.6394 50.589 60.5822C50.6586 60.525 50.715 60.4562 50.7583 60.3758C50.8031 60.2939 50.8333 60.2019 50.8487 60.0999L52.1565 60.1092C52.1411 60.3101 52.0847 60.515 51.9873 60.7236C51.8899 60.9308 51.7515 61.1225 51.5722 61.2987C51.3944 61.4734 51.1741 61.6141 50.9113 61.7207C50.6485 61.8274 50.3432 61.8807 49.9954 61.8807C49.5595 61.8807 49.1684 61.7872 48.8221 61.6002C48.4774 61.4131 48.2045 61.1379 48.0035 60.7747C47.8041 60.4114 47.7044 59.9669 47.7044 59.4413C47.7044 58.9127 47.8064 58.4674 48.0105 58.1057C48.2146 57.7424 48.4897 57.468 48.836 57.2825C49.1823 57.0955 49.5687 57.0019 49.9954 57.0019C50.2953 57.0019 50.5713 57.0429 50.8232 57.1248C51.0752 57.2068 51.2963 57.3266 51.4864 57.4843C51.6766 57.6404 51.8296 57.8329 51.9455 58.0616C52.0615 58.2904 52.1318 58.5525 52.1565 58.8477Z"
									fill="white"
								/>
								<path
									d="M57.175 58.8477H55.8672C55.858 58.7395 55.8332 58.6414 55.793 58.5532C55.7544 58.4651 55.7003 58.3894 55.6307 58.326C55.5627 58.2611 55.48 58.2116 55.3826 58.1776C55.2852 58.142 55.1747 58.1243 55.051 58.1243C54.8346 58.1243 54.6514 58.1768 54.5014 58.2819C54.353 58.3871 54.2402 58.5378 54.1629 58.7341C54.0872 58.9304 54.0493 59.1662 54.0493 59.4413C54.0493 59.732 54.0879 59.9754 54.1652 60.1718C54.2441 60.3666 54.3577 60.5134 54.5061 60.6123C54.6545 60.7097 54.833 60.7584 55.0417 60.7584C55.1608 60.7584 55.2674 60.7437 55.3617 60.7144C55.456 60.6835 55.538 60.6394 55.6075 60.5822C55.6771 60.525 55.7335 60.4562 55.7768 60.3758C55.8216 60.2939 55.8518 60.2019 55.8672 60.0999L57.175 60.1092C57.1596 60.3101 57.1032 60.515 57.0058 60.7236C56.9084 60.9308 56.77 61.1225 56.5907 61.2987C56.4129 61.4734 56.1926 61.6141 55.9298 61.7207C55.667 61.8274 55.3617 61.8807 55.0139 61.8807C54.578 61.8807 54.1869 61.7872 53.8406 61.6002C53.4959 61.4131 53.223 61.1379 53.022 60.7747C52.8226 60.4114 52.7229 59.9669 52.7229 59.4413C52.7229 58.9127 52.8249 58.4674 53.029 58.1057C53.2331 57.7424 53.5082 57.468 53.8545 57.2825C54.2008 57.0955 54.5872 57.0019 55.0139 57.0019C55.3138 57.0019 55.5898 57.0429 55.8417 57.1248C56.0937 57.2068 56.3148 57.3266 56.5049 57.4843C56.6951 57.6404 56.8481 57.8329 56.964 58.0616C57.08 58.2904 57.1503 58.5525 57.175 58.8477Z"
									fill="white"
								/>
								<path
									d="M57.76 57.0669H59.0492V59.5526H59.0863L60.7744 57.0669H61.8689V61.8158H60.5797V59.3208H60.5518L58.8359 61.8158H57.76V57.0669Z"
									fill="white"
								/>
								<path
									d="M67.0793 59.4413C67.0793 59.97 66.9765 60.416 66.7709 60.7793C66.5653 61.141 66.2878 61.4154 65.9384 61.6025C65.5891 61.788 65.1995 61.8807 64.7697 61.8807C64.3369 61.8807 63.9458 61.7872 63.5964 61.6002C63.2486 61.4116 62.9719 61.1364 62.7663 60.7747C62.5622 60.4114 62.4602 59.9669 62.4602 59.4413C62.4602 58.9127 62.5622 58.4674 62.7663 58.1057C62.9719 57.7424 63.2486 57.468 63.5964 57.2825C63.9458 57.0955 64.3369 57.0019 64.7697 57.0019C65.1995 57.0019 65.5891 57.0955 65.9384 57.2825C66.2878 57.468 66.5653 57.7424 66.7709 58.1057C66.9765 58.4674 67.0793 58.9127 67.0793 59.4413ZM65.7529 59.4413C65.7529 59.1569 65.715 58.9173 65.6393 58.7225C65.5651 58.5262 65.4546 58.3778 65.3077 58.2773C65.1624 58.1753 64.9831 58.1243 64.7697 58.1243C64.5564 58.1243 64.3763 58.1753 64.2295 58.2773C64.0842 58.3778 63.9736 58.5262 63.8979 58.7225C63.8237 58.9173 63.7866 59.1569 63.7866 59.4413C63.7866 59.7258 63.8237 59.9662 63.8979 60.1625C63.9736 60.3573 64.0842 60.5057 64.2295 60.6077C64.3763 60.7082 64.5564 60.7584 64.7697 60.7584C64.9831 60.7584 65.1624 60.7082 65.3077 60.6077C65.4546 60.5057 65.5651 60.3573 65.6393 60.1625C65.715 59.9662 65.7529 59.7258 65.7529 59.4413Z"
									fill="white"
								/>
								<path
									d="M67.6758 61.8158V57.0669H68.9651V58.9219H70.6717V57.0669H71.961V61.8158H70.6717V59.9608H68.9651V61.8158H67.6758Z"
									fill="white"
								/>
								<path
									d="M73.8067 61.8158H72.4155L73.983 57.0669H75.7453L77.3128 61.8158H75.9215L74.8827 58.3747H74.8456L73.8067 61.8158ZM73.547 59.9422H76.1627V60.9068H73.547V59.9422Z"
									fill="white"
								/>
								<path
									d="M77.6125 61.8158V60.8234L77.7424 60.8048C77.9 60.7801 78.0307 60.7121 78.1342 60.6008C78.2394 60.4879 78.3197 60.3094 78.3754 60.0651C78.4326 59.8193 78.4689 59.4846 78.4844 59.0611L78.5586 57.0669H81.9997V61.8158H80.7568V58.0686H79.7458L79.6716 59.5619C79.6438 60.1153 79.5696 60.5567 79.449 60.886C79.3284 61.2152 79.1452 61.4525 78.8995 61.5978C78.6537 61.7432 78.3298 61.8158 77.9279 61.8158H77.6125Z"
									fill="white"
								/>
								<path
									d="M83.4264 58.6622H84.4095C84.8099 58.6622 85.1593 58.7264 85.4576 58.8547C85.7575 58.983 85.9902 59.1639 86.1556 59.3973C86.3226 59.6307 86.4053 59.9051 86.4037 60.2205C86.4053 60.5358 86.3226 60.8133 86.1556 61.0529C85.9902 61.2925 85.7575 61.4796 85.4576 61.6141C85.1593 61.7486 84.8099 61.8158 84.4095 61.8158H82.638V57.0669H83.9272V60.8048H84.4095C84.5657 60.8048 84.6963 60.7793 84.8014 60.7283C84.9081 60.6773 84.9885 60.6069 85.0426 60.5173C85.0982 60.4276 85.1253 60.3256 85.1237 60.2112C85.1253 60.0489 85.065 59.9144 84.9429 59.8077C84.8207 59.6995 84.643 59.6454 84.4095 59.6454H83.4264V58.6622Z"
									fill="white"
								/>
								<path
									d="M87.1376 61.8158V57.0669H88.4269V58.9219H90.1335V57.0669H91.4228V61.8158H90.1335V59.9608H88.4269V61.8158H87.1376Z"
									fill="white"
								/>
								<path
									d="M92.8512 58.6622H93.8344C94.2347 58.6622 94.5841 58.7264 94.8825 58.8547C95.1824 58.983 95.415 59.1639 95.5804 59.3973C95.7474 59.6307 95.8301 59.9051 95.8285 60.2205C95.8301 60.5358 95.7474 60.8133 95.5804 61.0529C95.415 61.2925 95.1824 61.4796 94.8825 61.6141C94.5841 61.7486 94.2347 61.8158 93.8344 61.8158H92.0628V57.0669H93.3521V60.8048H93.8344C93.9905 60.8048 94.1211 60.7793 94.2262 60.7283C94.3329 60.6773 94.4133 60.6069 94.4674 60.5173C94.523 60.4276 94.5501 60.3256 94.5486 60.2112C94.5501 60.0489 94.4898 59.9144 94.3677 59.8077C94.2456 59.6995 94.0678 59.6454 93.8344 59.6454H92.8512V58.6622ZM97.4517 57.0669V61.8158H96.1625V57.0669H97.4517Z"
									fill="white"
								/>
								<path
									d="M99.443 57.0669L100.222 58.4489H100.259L101.048 57.0669H102.485L101.066 59.4413L102.541 61.8158H101.066L100.259 60.406H100.222L99.4152 61.8158H97.9497L99.4059 59.4413L97.9961 57.0669H99.443Z"
									fill="white"
								/>
								<path
									d="M12.1213 65.9711C12.1213 66.4998 12.0185 66.9458 11.8129 67.3091C11.6073 67.6708 11.3298 67.9452 10.9805 68.1323C10.6311 68.3178 10.2415 68.4105 9.81178 68.4105C9.37893 68.4105 8.98783 68.317 8.63846 68.1299C8.29064 67.9413 8.01393 67.6662 7.80832 67.3044C7.60427 66.9412 7.50224 66.4967 7.50224 65.9711C7.50224 65.4424 7.60427 64.9972 7.80832 64.6355C8.01393 64.2722 8.29064 63.9978 8.63846 63.8123C8.98783 63.6253 9.37893 63.5317 9.81178 63.5317C10.2415 63.5317 10.6311 63.6253 10.9805 63.8123C11.3298 63.9978 11.6073 64.2722 11.8129 64.6355C12.0185 64.9972 12.1213 65.4424 12.1213 65.9711ZM10.795 65.9711C10.795 65.6867 10.7571 65.4471 10.6813 65.2523C10.6071 65.056 10.4966 64.9076 10.3497 64.8071C10.2044 64.7051 10.0251 64.654 9.81178 64.654C9.59845 64.654 9.41835 64.7051 9.27149 64.8071C9.12618 64.9076 9.01565 65.056 8.9399 65.2523C8.8657 65.4471 8.8286 65.6867 8.8286 65.9711C8.8286 66.2556 8.8657 66.496 8.9399 66.6923C9.01565 66.8871 9.12618 67.0355 9.27149 67.1375C9.41835 67.238 9.59845 67.2882 9.81178 67.2882C10.0251 67.2882 10.2044 67.238 10.3497 67.1375C10.4966 67.0355 10.6071 66.8871 10.6813 66.6923C10.7571 66.496 10.795 66.2556 10.795 65.9711Z"
									fill="white"
								/>
								<path
									d="M12.7178 68.3456V63.5967H14.7677C15.1201 63.5967 15.4285 63.6662 15.6929 63.8054C15.9572 63.9445 16.1628 64.14 16.3097 64.392C16.4565 64.644 16.53 64.9385 16.53 65.2755C16.53 65.6156 16.4542 65.9101 16.3027 66.159C16.1528 66.4078 15.9418 66.5995 15.6697 66.734C15.3992 66.8685 15.083 66.9358 14.7213 66.9358H13.497V65.934H14.4616C14.6131 65.934 14.7422 65.9077 14.8488 65.8552C14.957 65.8011 15.0397 65.7246 15.0969 65.6256C15.1557 65.5267 15.1851 65.41 15.1851 65.2755C15.1851 65.1394 15.1557 65.0235 15.0969 64.9277C15.0397 64.8303 14.957 64.7561 14.8488 64.7051C14.7422 64.6525 14.6131 64.6262 14.4616 64.6262H14.0071V68.3456H12.7178Z"
									fill="white"
								/>
								<path
									d="M20.3421 63.5967V64.6355H18.2923V68.3456H17.003V63.5967H20.3421Z"
									fill="white"
								/>
								<path
									d="M21.4569 68.3456H20.0656L21.6331 63.5967H23.3954L24.9629 68.3456H23.5716L22.5328 64.9045H22.4957L21.4569 68.3456ZM21.1972 66.472H23.8128V67.4366H21.1972V66.472Z"
									fill="white"
								/>
								<path
									d="M25.4203 68.3456V63.5967H26.7096V65.4517H28.4162V63.5967H29.7055V68.3456H28.4162V66.4905H26.7096V68.3456H25.4203Z"
									fill="white"
								/>
								<path
									d="M30.3176 63.5967H31.6069V66.0824H31.644L33.3321 63.5967H34.4266V68.3456H33.1373V65.8506H33.1095L31.3936 68.3456H30.3176V63.5967Z"
									fill="white"
								/>
								<path
									d="M36.9008 68.4105C36.5267 68.4105 36.1943 68.3464 35.9037 68.2181C35.6146 68.0882 35.3874 67.9097 35.2219 67.6824C35.0565 67.4552 34.9731 67.1939 34.9715 66.8987H36.27C36.2716 66.9899 36.2994 67.0718 36.3535 67.1445C36.4092 67.2156 36.4849 67.2712 36.5808 67.3114C36.6766 67.3516 36.7864 67.3717 36.91 67.3717C37.0291 67.3717 37.1342 67.3508 37.2254 67.3091C37.3166 67.2658 37.3877 67.2063 37.4387 67.1305C37.4897 67.0548 37.5145 66.9682 37.5129 66.8708C37.5145 66.775 37.4851 66.69 37.4248 66.6158C37.3661 66.5416 37.2834 66.4836 37.1767 66.4418C37.07 66.4001 36.9471 66.3792 36.808 66.3792H36.3257V65.4888H36.808C36.9363 65.4888 37.0492 65.4679 37.1466 65.4262C37.2455 65.3845 37.322 65.3265 37.3761 65.2523C37.4318 65.1781 37.4588 65.0931 37.4573 64.9972C37.4588 64.9045 37.4364 64.8225 37.39 64.7514C37.3437 64.6803 37.2787 64.6247 37.1953 64.5845C37.1133 64.5443 37.0183 64.5242 36.91 64.5242C36.7926 64.5242 36.6874 64.5451 36.5947 64.5868C36.5035 64.6285 36.4316 64.6865 36.379 64.7607C36.3265 64.8349 36.2994 64.9199 36.2979 65.0158H35.0643C35.0658 64.7252 35.1454 64.4685 35.3031 64.2459C35.4623 64.0233 35.6803 63.8486 35.957 63.7219C36.2337 63.5951 36.5514 63.5317 36.91 63.5317C37.261 63.5317 37.5709 63.5913 37.8399 63.7103C38.1104 63.8293 38.3214 63.9932 38.4729 64.2019C38.626 64.409 38.7017 64.6463 38.7002 64.9137C38.7033 65.1796 38.6121 65.3976 38.4265 65.5677C38.2426 65.7377 38.0092 65.8382 37.7263 65.8691V65.9062C38.1096 65.9479 38.3979 66.0654 38.5912 66.2587C38.7844 66.4504 38.8795 66.6915 38.8764 66.9821C38.8779 67.2604 38.7945 67.507 38.626 67.7218C38.459 67.9367 38.2264 68.1052 37.928 68.2273C37.6312 68.3495 37.2888 68.4105 36.9008 68.4105Z"
									fill="white"
								/>
								<path
									d="M40.6764 68.3456H39.2851L40.8526 63.5967H42.6149L44.1824 68.3456H42.7911L41.7523 64.9045H41.7152L40.6764 68.3456ZM40.4167 66.472H43.0323V67.4366H40.4167V66.472Z"
									fill="white"
								/>
								<path
									d="M43.9105 64.6355V63.5967H48.038V64.6355H46.6097V68.3456H45.3389V64.6355H43.9105Z"
									fill="white"
								/>
								<path
									d="M52.9006 65.9711C52.9006 66.4998 52.7978 66.9458 52.5922 67.3091C52.3866 67.6708 52.1091 67.9452 51.7597 68.1323C51.4104 68.3178 51.0208 68.4105 50.5911 68.4105C50.1582 68.4105 49.7671 68.317 49.4177 68.1299C49.0699 67.9413 48.7932 67.6662 48.5876 67.3044C48.3835 66.9412 48.2815 66.4967 48.2815 65.9711C48.2815 65.4424 48.3835 64.9972 48.5876 64.6355C48.7932 64.2722 49.0699 63.9978 49.4177 63.8123C49.7671 63.6253 50.1582 63.5317 50.5911 63.5317C51.0208 63.5317 51.4104 63.6253 51.7597 63.8123C52.1091 63.9978 52.3866 64.2722 52.5922 64.6355C52.7978 64.9972 52.9006 65.4424 52.9006 65.9711ZM51.5742 65.9711C51.5742 65.6867 51.5364 65.4471 51.4606 65.2523C51.3864 65.056 51.2759 64.9076 51.129 64.8071C50.9837 64.7051 50.8044 64.654 50.5911 64.654C50.3777 64.654 50.1976 64.7051 50.0508 64.8071C49.9055 64.9076 49.7949 65.056 49.7192 65.2523C49.645 65.4471 49.6079 65.6867 49.6079 65.9711C49.6079 66.2556 49.645 66.496 49.7192 66.6923C49.7949 66.8871 49.9055 67.0355 50.0508 67.1375C50.1976 67.238 50.3777 67.2882 50.5911 67.2882C50.8044 67.2882 50.9837 67.238 51.129 67.1375C51.2759 67.0355 51.3864 66.8871 51.4606 66.6923C51.5364 66.496 51.5742 66.2556 51.5742 65.9711Z"
									fill="white"
								/>
								<path
									d="M53.4971 68.3456V63.5967H55.5469C55.8994 63.5967 56.2078 63.6662 56.4722 63.8054C56.7365 63.9445 56.9421 64.14 57.089 64.392C57.2358 64.644 57.3092 64.9385 57.3092 65.2755C57.3092 65.6156 57.2335 65.9101 57.082 66.159C56.9321 66.4078 56.721 66.5995 56.449 66.734C56.1784 66.8685 55.8623 66.9358 55.5006 66.9358H54.2762V65.934H55.2409C55.3924 65.934 55.5214 65.9077 55.6281 65.8552C55.7363 65.8011 55.819 65.7246 55.8762 65.6256C55.935 65.5267 55.9643 65.41 55.9643 65.2755C55.9643 65.1394 55.935 65.0235 55.8762 64.9277C55.819 64.8303 55.7363 64.7561 55.6281 64.7051C55.5214 64.6525 55.3924 64.6262 55.2409 64.6262H54.7864V68.3456H53.4971Z"
									fill="white"
								/>
								<path
									d="M62.3828 65.9711C62.3828 66.4998 62.28 66.9458 62.0744 67.3091C61.8688 67.6708 61.5913 67.9452 61.242 68.1323C60.8926 68.3178 60.503 68.4105 60.0733 68.4105C59.6404 68.4105 59.2493 68.317 58.9 68.1299C58.5521 67.9413 58.2754 67.6662 58.0698 67.3044C57.8658 66.9412 57.7637 66.4967 57.7637 65.9711C57.7637 65.4424 57.8658 64.9972 58.0698 64.6355C58.2754 64.2722 58.5521 63.9978 58.9 63.8123C59.2493 63.6253 59.6404 63.5317 60.0733 63.5317C60.503 63.5317 60.8926 63.6253 61.242 63.8123C61.5913 63.9978 61.8688 64.2722 62.0744 64.6355C62.28 64.9972 62.3828 65.4424 62.3828 65.9711ZM61.0564 65.9711C61.0564 65.6867 61.0186 65.4471 60.9428 65.2523C60.8686 65.056 60.7581 64.9076 60.6112 64.8071C60.4659 64.7051 60.2866 64.654 60.0733 64.654C59.8599 64.654 59.6799 64.7051 59.533 64.8071C59.3877 64.9076 59.2771 65.056 59.2014 65.2523C59.1272 65.4471 59.0901 65.6867 59.0901 65.9711C59.0901 66.2556 59.1272 66.496 59.2014 66.6923C59.2771 66.8871 59.3877 67.0355 59.533 67.1375C59.6799 67.238 59.8599 67.2882 60.0733 67.2882C60.2866 67.2882 60.4659 67.238 60.6112 67.1375C60.7581 67.0355 60.8686 66.8871 60.9428 66.6923C61.0186 66.496 61.0564 66.2556 61.0564 65.9711Z"
									fill="white"
								/>
								<path
									d="M62.9793 68.3456V63.5967H65.0384C65.4002 63.5967 65.7039 63.6454 65.9497 63.7427C66.1971 63.8401 66.3833 63.9777 66.5086 64.1555C66.6353 64.3333 66.6987 64.5427 66.6987 64.7839C66.6987 64.9586 66.6601 65.1178 66.5828 65.2616C66.507 65.4053 66.4004 65.5259 66.2628 65.6233C66.1252 65.7192 65.9644 65.7856 65.7805 65.8227V65.8691C65.9845 65.8768 66.17 65.9286 66.337 66.0245C66.5039 66.1188 66.6369 66.2494 66.7358 66.4163C66.8347 66.5818 66.8842 66.7765 66.8842 67.0007C66.8842 67.2604 66.8162 67.4915 66.6802 67.694C66.5457 67.8965 66.354 68.0557 66.1051 68.1717C65.8562 68.2876 65.5594 68.3456 65.2147 68.3456H62.9793ZM64.2686 67.316H64.8715C65.0879 67.316 65.2502 67.2758 65.3584 67.1955C65.4666 67.1135 65.5208 66.993 65.5208 66.8337C65.5208 66.7224 65.4952 66.6281 65.4442 66.5508C65.3932 66.4735 65.3206 66.4148 65.2263 66.3746C65.1335 66.3344 65.0214 66.3143 64.89 66.3143H64.2686V67.316ZM64.2686 65.5166H64.7973C64.9101 65.5166 65.0098 65.4989 65.0964 65.4633C65.183 65.4278 65.2502 65.3767 65.2981 65.3103C65.3476 65.2422 65.3724 65.1595 65.3724 65.0622C65.3724 64.9153 65.3198 64.8032 65.2147 64.7259C65.1096 64.6471 64.9766 64.6077 64.8158 64.6077H64.2686V65.5166Z"
									fill="white"
								/>
								<path
									d="M68.512 64.6355V63.5967H72.6395V64.6355H71.2111V68.3456H69.9404V64.6355H68.512Z"
									fill="white"
								/>
								<path
									d="M77.5021 65.9711C77.5021 66.4998 77.3993 66.9458 77.1937 67.3091C76.9881 67.6708 76.7106 67.9452 76.3612 68.1323C76.0118 68.3178 75.6223 68.4105 75.1925 68.4105C74.7597 68.4105 74.3686 68.317 74.0192 68.1299C73.6714 67.9413 73.3947 67.6662 73.1891 67.3044C72.985 66.9412 72.883 66.4967 72.883 65.9711C72.883 65.4424 72.985 64.9972 73.1891 64.6355C73.3947 64.2722 73.6714 63.9978 74.0192 63.8123C74.3686 63.6253 74.7597 63.5317 75.1925 63.5317C75.6223 63.5317 76.0118 63.6253 76.3612 63.8123C76.7106 63.9978 76.9881 64.2722 77.1937 64.6355C77.3993 64.9972 77.5021 65.4424 77.5021 65.9711ZM76.1757 65.9711C76.1757 65.6867 76.1378 65.4471 76.0621 65.2523C75.9879 65.056 75.8774 64.9076 75.7305 64.8071C75.5852 64.7051 75.4059 64.654 75.1925 64.654C74.9792 64.654 74.7991 64.7051 74.6523 64.8071C74.5069 64.9076 74.3964 65.056 74.3207 65.2523C74.2465 65.4471 74.2094 65.6867 74.2094 65.9711C74.2094 66.2556 74.2465 66.496 74.3207 66.6923C74.3964 66.8871 74.5069 67.0355 74.6523 67.1375C74.7991 67.238 74.9792 67.2882 75.1925 67.2882C75.4059 67.2882 75.5852 67.238 75.7305 67.1375C75.8774 67.0355 75.9879 66.8871 76.0621 66.6923C76.1378 66.496 76.1757 66.2556 76.1757 65.9711Z"
									fill="white"
								/>
								<path
									d="M78.0986 68.3456V63.5967H80.1484C80.5009 63.5967 80.8093 63.6662 81.0736 63.8054C81.338 63.9445 81.5436 64.14 81.6904 64.392C81.8373 64.644 81.9107 64.9385 81.9107 65.2755C81.9107 65.6156 81.835 65.9101 81.6835 66.159C81.5335 66.4078 81.3225 66.5995 81.0504 66.734C80.7799 66.8685 80.4638 66.9358 80.1021 66.9358H78.8777V65.934H79.8423C79.9938 65.934 80.1229 65.9077 80.2296 65.8552C80.3378 65.8011 80.4205 65.7246 80.4777 65.6256C80.5364 65.5267 80.5658 65.41 80.5658 65.2755C80.5658 65.1394 80.5364 65.0235 80.4777 64.9277C80.4205 64.8303 80.3378 64.7561 80.2296 64.7051C80.1229 64.6525 79.9938 64.6262 79.8423 64.6262H79.3879V68.3456H78.0986Z"
									fill="white"
								/>
								<path
									d="M85.7229 63.5967V64.6355H83.673V68.3456H82.3838V63.5967H85.7229Z"
									fill="white"
								/>
								<path
									d="M90.5808 65.9711C90.5808 66.4998 90.478 66.9458 90.2724 67.3091C90.0668 67.6708 89.7893 67.9452 89.4399 68.1323C89.0906 68.3178 88.701 68.4105 88.2712 68.4105C87.8384 68.4105 87.4473 68.317 87.0979 68.1299C86.7501 67.9413 86.4734 67.6662 86.2678 67.3044C86.0637 66.9412 85.9617 66.4967 85.9617 65.9711C85.9617 65.4424 86.0637 64.9972 86.2678 64.6355C86.4734 64.2722 86.7501 63.9978 87.0979 63.8123C87.4473 63.6253 87.8384 63.5317 88.2712 63.5317C88.701 63.5317 89.0906 63.6253 89.4399 63.8123C89.7893 63.9978 90.0668 64.2722 90.2724 64.6355C90.478 64.9972 90.5808 65.4424 90.5808 65.9711ZM89.2544 65.9711C89.2544 65.6867 89.2165 65.4471 89.1408 65.2523C89.0666 65.056 88.9561 64.9076 88.8092 64.8071C88.6639 64.7051 88.4846 64.654 88.2712 64.654C88.0579 64.654 87.8778 64.7051 87.731 64.8071C87.5856 64.9076 87.4751 65.056 87.3994 65.2523C87.3252 65.4471 87.2881 65.6867 87.2881 65.9711C87.2881 66.2556 87.3252 66.496 87.3994 66.6923C87.4751 66.8871 87.5856 67.0355 87.731 67.1375C87.8778 67.238 88.0579 67.2882 88.2712 67.2882C88.4846 67.2882 88.6639 67.238 88.8092 67.1375C88.9561 67.0355 89.0666 66.8871 89.1408 66.6923C89.2165 66.496 89.2544 66.2556 89.2544 65.9711Z"
									fill="white"
								/>
								<path
									d="M91.1773 68.3456V63.5967H93.2364C93.5981 63.5967 93.9019 63.6454 94.1477 63.7427C94.395 63.8401 94.5813 63.9777 94.7065 64.1555C94.8333 64.3333 94.8967 64.5427 94.8967 64.7839C94.8967 64.9586 94.858 65.1178 94.7807 65.2616C94.705 65.4053 94.5983 65.5259 94.4607 65.6233C94.3232 65.7192 94.1624 65.7856 93.9784 65.8227V65.8691C94.1825 65.8768 94.368 65.9286 94.5349 66.0245C94.7019 66.1188 94.8348 66.2494 94.9338 66.4163C95.0327 66.5818 95.0822 66.7765 95.0822 67.0007C95.0822 67.2604 95.0142 67.4915 94.8781 67.694C94.7436 67.8965 94.5519 68.0557 94.3031 68.1717C94.0542 68.2876 93.7574 68.3456 93.4126 68.3456H91.1773ZM92.4666 67.316H93.0694C93.2859 67.316 93.4482 67.2758 93.5564 67.1955C93.6646 67.1135 93.7187 66.993 93.7187 66.8337C93.7187 66.7224 93.6932 66.6281 93.6422 66.5508C93.5912 66.4735 93.5185 66.4148 93.4242 66.3746C93.3315 66.3344 93.2194 66.3143 93.088 66.3143H92.4666V67.316ZM92.4666 65.5166H92.9952C93.1081 65.5166 93.2078 65.4989 93.2944 65.4633C93.3809 65.4278 93.4482 65.3767 93.4961 65.3103C93.5456 65.2422 93.5703 65.1595 93.5703 65.0622C93.5703 64.9153 93.5178 64.8032 93.4126 64.7259C93.3075 64.6471 93.1746 64.6077 93.0138 64.6077H92.4666V65.5166Z"
									fill="white"
								/>
								<defs>
									<linearGradient
										id="paint0_linear_803_902"
										x1="20.6419"
										y1="3.91787"
										x2="51.9848"
										y2="47.4498"
										gradientUnits="userSpaceOnUse">
										<stop stopColor="white" />
										<stop offset="0.453125" stopColor="#E7EAEC" />
										<stop offset="1" stopColor="#D7D7D7" />
									</linearGradient>
									<linearGradient
										id="paint1_linear_803_902"
										x1="35.2937"
										y1="14.6261"
										x2="50.7905"
										y2="38.2289"
										gradientUnits="userSpaceOnUse">
										<stop stopColor="white" />
										<stop offset="0.453125" stopColor="#E7EAEC" />
										<stop offset="1" stopColor="#D7D7D7" />
									</linearGradient>
									<linearGradient
										id="paint2_linear_803_902"
										x1="35.5841"
										y1="2.15742"
										x2="52.8668"
										y2="26.0586"
										gradientUnits="userSpaceOnUse">
										<stop stopColor="white" />
										<stop offset="0.453125" stopColor="#E7EAEC" />
										<stop offset="1" stopColor="#D7D7D7" />
									</linearGradient>
									<linearGradient
										id="paint3_linear_803_902"
										x1="35.5694"
										y1="23.357"
										x2="53.7306"
										y2="47.6135"
										gradientUnits="userSpaceOnUse">
										<stop stopColor="white" />
										<stop offset="0.453125" stopColor="#E7EAEC" />
										<stop offset="1" stopColor="#D7D7D7" />
									</linearGradient>
								</defs>
							</svg>
						</div>
						<ul className={Style.footer__list}>
							<li className={Style.footer__list_item}>
								<Link to="/about">О союзе</Link>
							</li>
							<li className={Style.footer__list_item}>
								<Link to="/news">Новости</Link>
							</li>
							<li className={Style.footer__list_item}>
								<Link to="/members">Членам СРО</Link>
							</li>
							<li className={Style.footer__list_item}>
								<Link to="/docs">Документация</Link>
							</li>
							<li className={Style.footer__list_item}>
								<Link to="/contacts">Контакты</Link>
							</li>
						</ul>
					</nav>
				</div>
				<div className={Style.footer__copyright}>
					<p className={Style.footer__copyright_par}>
						2022 Союз профессиональных организаторов торгов
					</p>
				</div>
			</div>
		</footer>
	);
}

export default Footer;
