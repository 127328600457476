import React from 'react';

function NewsPageOne() {
	return (
		<div className="newspage">
			<div className="newspage__container">
				<div className="newspage__head">
					<h1 className="newspage__head-head">Единые правила игры против негативных тенденций</h1>
				</div>

				<div className="newspage__content">
					<div className="newspage__text">
						<p className="newspage__text-text">
							В пятницу, 18 февраля, прошло заседание Экспертного совета по развитию конкуренции в
							сфере банкротства Комитета ГД. На нём присутствовали представители различных
							министерств и ведомств: Минэкономразвития, Департамента оборонно-промышленного
							комплекса Минпромторга России, Департамента экономического развития и финансов
							Правительства РФ, Управления контроля строительства и природных ресурсов ФАС России,
							Департамента экономического законодательства Минюста, Департамента стратегического
							развития финансового рынка Банка России и др., депутаты Государственной Думы, эксперты
							(в том числе и представители научного сообщества) и руководители СРО. <br />
							<br /> На повестке стояли такие важные вопросы, как организация и порядок проведения
							торгов, электронные торговые площадки и реформа банкротства, а также реабилитация
							предприятий (реструктуризация долгов). <br />
							<br />
							<span>Необходимость саморегулирования</span> <br />
							На заседании рассмотрели проект федерального закона № 1172553-7 «О внесении изменений
							в Федеральный закон «О несостоятельности (банкротстве)». Всем участникам экспертного
							совета понятно, что давно назрела необходимость введения новой реабилитационной
							процедуры банкротства – реструктуризации долгов. <br />
							<br />- Однако тенденция сейчас такова, что принимаются законы, ограничивающие
							электронный рынок перечнем из восьми Электронных торговых площадок. Попасть в перечень
							практически невозможно. Кроме того, идёт уничтожение системы саморегулирования
							бизнеса. Хотя как раз саморегулирование - стратегическая задача развитого государства,
							- говорит одна из участниц экспертного совета Генеральный директор Союза Торговых
							Электронных Площадок Колищук Ольга Васильевна. <br />
							<br />
							Анализируя саморегулируемые организации на рынке банкротства, можно сделать вывод, что
							активное развитие рынка электронных торгов повлекло за собой появление большего
							количество организаций, стремящихся занять нишу проведения таких торгов. Возникло
							целое профессиональное сообщество операторов электронных торговых площадок (ЭТП).
							Появилась необходимость введения механизма контроля данных компаний. <br />
							<br />
							Опыт внедрения саморегулирования в данной сфере уже показал, что регулирование
							профессионального сообщества самими профессионалами способствует более оперативной
							реакции на изменение законодательства, даёт толчок для высокотехнологичных
							программно-технических средств, - это позитивно сказывается на экономике всего
							государства. <br />
							<br />
							Уже сейчас образовалась устойчивая экосистема в данной отрасли (которая продолжает
							совершенствоваться): СРО Операторов ЭТП тесно связаны с СРО Арбитражных управляющих.
							Работает механизм аккредитации операторов ЭТП при СРО Арбитражных управляющих.
							<br />
							<br /> На развитии сферы электронных торгов положительно скажется наличие
							ответственности, предусмотренной любой СРО в случае ненадлежащего исполнения
							возложенных на ее члена функций и наличие компенсационного фонда. Фонд позволяет
							возместить убытки, нанесенные третьим лицам членами СРО. <br />
							<br />
							На данный же момент, организации, привлекаемые для проведения торгов по
							исполнительному производству, не несут за свои действия практически никакой реальной
							ответственности, при этом работая зачастую с огромными суммами. Поэтому так необходимо
							внедрение механизма саморегулирования среди организаторов торгов. <br />
							<br />
							<span>Конкуренции нет</span> У большинства операторов электронных площадок, перечень
							которых утвержден Правительством РФ в соответствии со статьей 241 Федерального закона
							от 5 апреля 2013 года № 44-ФЗ «О контрактной системе в сфере закупок товаров, работ,
							услуг для обеспечения государственных и муниципальных нужд», отсутствует необходимый
							функционал и опыт реализации имущества в рамках Федерального закона «О
							несостоятельности (банкротстве)» 127-ФЗ. <br />
							<br />
							Сокращение количества ЭТП может привести к негативным последствиям, одно из самых
							распространённых - невозможность проведения торгов из-за технических сбоев.
							Обязанность выбора ЭТП из числа восьми площадок Перечня не обеспечивает соблюдение
							конкуренции, поскольку Законом о контрактной системе № 44-ФЗ не регламентирована
							процедура выбора ЭТП, однако со всей очевидностью содержит риски картельного сговора и
							иных ограничивающих конкуренцию соглашений между операторами ЭТП, включая соглашение о
							тарифах комиссионного вознаграждения. <br />
							<br />- В целях недопущения указанных негативных факторов предлагается внести
							следующие изменения: «Организатор торгов в целях реализации на торгах имущества
							должника, в том числе имущественных прав, в электронной форме привлекает оператора
							электронной площадки из числа операторов электронных площадок, перечень которых
							утвержден Правительством Российской Федерации в соответствии со статьей 241
							Федерального закона от 5 апреля 2013 года № 44-ФЗ «О контрактной системе в сфере
							закупок товаров, работ, услуг для обеспечения государственных и муниципальных нужд.»,
							а также операторов электронных площадок, соответствующих требованиям, установленным
							Федеральным законом от 26 октября 2002 года № Федеральным законом от 26 октября 2002
							г. № 127-ФЗ «О несостоятельности (банкротстве)». При этом должны быть пересмотрены
							критерии по отбору операторов электронных торговых площадок установленных Федеральным
							законом от 26 октября 2002 года № Федеральным законом от 26 октября 2002 г. № 127-ФЗ
							«О несостоятельности (банкротстве)», - говорит Генеральный директор «ТендерСтандарт»
							Войтко Алексей Владимирович. <br />
							<br />
							При этом он, ссылаясь на открытые данные (Единый федеральный реестр сведений о
							банкротстве bankrot.fedresurs.ru) отмечает, что из 8 операторов электронных площадок,
							указанных в перечне, только 2 оператора электронных площадок имеют фактический опыт
							реализации в сфере торгов по банкротству. И их доля в общем объеме не превышает 15%.
							Из приведенной статистики также следует, что почти 80% торгов не состоялось или
							отменено на сумму более 30 млрд рублей. Хотя существует и эффективно работает система
							из трех независимых друг от друга саморегулируемых организаций операторов электронных
							площадок, в которые входят сорок восемь операторов ЭТП с огромным опытом проведения
							торгов. Но попасть в перечень они не могут. <br />
							<br />
							На заседании Экспертного совета по развитию конкуренции в сфере банкротства Комитета
							ГД представители ЭТП были единогласны в том, что сокращение количества ЭТП до восьми -
							прямое ограничение конкуренции в сфере реализации имущества должника. <br />
							<br />
							Кстати, все участники заседания отметили эффективность института саморегулирования в
							сфере электронных торгов, и высказались за сохранение механизма саморегулирования.
							Однако пока все эти замечания не нашли своё отражение в доработанной редакции проекта
							федерального закона № 1172553-7 «О внесении изменений в Федеральный закон «О
							несостоятельности (банкротстве)».
							<br />
							<br />
							<span>Отлаженный механизм</span> <br />
							<br />
							Только разработка и утверждение единых правил «игры» для организаторов и участников
							электронных торгов является гарантом экономического прогресса и правовой
							справедливости. - СРО являются на данный момент уже налаженным, хорошо
							зарекомендовавшим себя инструментов развития и контроля отрасли. <br />
							<br />
							Многие члены СРО являются многопрофильными электронными площадками, осуществляющими
							несколько видов торговых процедур. Эффективность саморегулирования подтверждается тем,
							что некоторые из федеральных ЭТП также состоят в одной из трех СРО. Наша концепция
							унификации критериев по выбору операторов электронных площадок для проведения торгов в
							электронной форме предполагает выбор операторов электронной площадки, являющихся
							членами саморегулируемой организации операторов электронных площадок. Уничтожение
							хорошо отлаженного и работающего механизма видится непозволительным, особенно в это
							сложное для экономики страны время, - рассказала Генеральный директор Союза Торговых
							Электронных Площадок Ольга Васильевна Колищук. <br />
							<br />
							Кстати, произошло одно из самых значимых событий для всего цифрового рынка России -
							подписание документа «Принципы взаимодействия участников цифровых рынков». Эти
							принципы разработала ФАС совместно с участниками рынка. Они направлены на то, чтобы
							достичь честной конкуренции и прозрачности и не допустить недобросовестных практик в
							отношении поставщиков и пользователей. Основа всех этих принципов – саморегулирование
							отрасли. <br />
							<br />- Для цифровых рынков — это, наверное, лучший вариант регулирования, ведь любой
							ФЗ со временем теряет свою актуальность. А вот саморегулирование – это гибкая система
							контроля, которая будет меняться в зависимости от современных реалий. Кроме того,
							«жесткое» регуляторное вмешательство в цифровые рынки сегодня недопустимо, потому что
							оно окажет только негативное влияние и будет тормозить развитие этой сферы, - считает
							Ольга Васильевна.
							<br />
							<br /> С этой позицией согласны все ведущие компании-экосистемники. Так, Сбер, Яндекс,
							VK, МТС, Ozon, Wildberries и другие компании уже подписали документ. Они чётко
							понимают, что принципы, основанные на саморегуляции — лучший вариант из всех
							доступных. Кстати, подобное единогласие в этом вопросе - уникальный случай в мире.
							Компании- конкуренты по самым разным сервисам согласны действовать сообща ради общей
							цели.
							<br />
							<br /> Подводя итоги первого заседания Экспертного совета по развитию конкуренции в
							сфере банкротства Комитета ГД, отметим, что все участники выразили надежду на
							взвешенную оценку Комитета по защите конкуренции положений законопроекта при
							подготовке заключения, при этом будут учтены позиции профессионального сообщества.
						</p>
					</div>
					<div className="newspage__nav">
						<ul className="newspage__nav-list">
							<span className="newspage__nav-list-head">Свежие новости</span>
							<li className="newspage__nav-list-item">
								<a href="">
									Все решит суд? Как ФАС стоит на страже <br />
									закона «О защите конкуренции»
								</a>
							</li>
							<li className="newspage__nav-list-item">
								<a href="">
									«Юридический спам» как способ <br />
									повлиять на результат торгов
								</a>
							</li>
							<li className="newspage__nav-list-item">
								<a href="">
									Реально ли попасть в перечень? <br />
									Вопросы конкуренции
								</a>
							</li>
							<li className="newspage__nav-list-item">
								<a href="">
									Прозрачные торги? Что необходимо
									<br /> реорганизовать в законодательстве о торгах
								</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
}

export default NewsPageOne;
