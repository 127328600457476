import React from 'react';

function Contacts() {
	return (
		<div className="cont">
			<div className="cont__container">
				<h1 className="cont__head">контакты</h1>

				<div className="cont__main">
					<div className="cont__main-head">
						<h3>Адрес местонахождения </h3>
					</div>

					<div className="cont__main-desc">
						<p className="cont__main-desc-top">121069, Москва г, ул Поварская,</p>
						<p className="cont__main-desc-bot"> д. 10, стр. 1, офис 400, помещ. 27/1</p>
					</div>

					<div className="cont__main-head">
						<h3>Контакты для связи</h3>
					</div>

					<div className="cont__main-desc">
						<a href="tel:+79889443196" className="cont__main-desc-top">
						+7 988 944-31-96
						</a>
						<a href="mailto:support@souzspot.ru" className="cont__main-desc-bot">
							support@souzspot.ru
						</a>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Contacts;
