import React from 'react';
import { CloseOutlined } from '@ant-design/icons';

import Style from './ModalForm.module.scss';

function ModalForm(props) {
	//закрытие модалки по нажатию на esc и на оверлей
	React.useEffect(() => {
		const close = (e) => {
			if (e.keyCode === 27) {
				props.closeModal();
			}
		};
		window.addEventListener('keydown', close);
		document.body.style.overflow = 'hidden';
		return () => {
			window.removeEventListener('keydown', close);
			document.body.style.overflow = 'unset';
		};
	}, []);

	return (
		<div
			className={Style.overlay}
			onClick={(e) => e.currentTarget === e.target && props.closeModal()}>
			<div open={props.open} onClose={props.handleClose} className={Style.form}>
				<button onClick={props.closeModal} className={Style.closeModal}>
					<CloseOutlined />
				</button>
				<form action="">
					<input type="text" name="ФИО" id="name" placeholder="ФИО" required />
					<input type="tel" name="phone" id="phone" placeholder="Телефон" required />
					<button className={Style.btn} type="submit">
						Вступить в СПОТ
					</button>
				</form>
				<p className={Style.form_copy}>
					Я согласен(на) с Политикой обработки <br />
					персональных данных
				</p>
			</div>
		</div>
	);
}

export default ModalForm;
