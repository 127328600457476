import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Header from './Components/Header';
import MobileHeader from './Components/MobileHeader';
import MobileMenu from './Components/MobileMenu';
import Footer from './Components/Footer';

import Home from './Pages/Home';
import About from './Pages/About';
import Contacts from './Pages/Contacts';
import Docs from './Pages/Docs';
import Members from './Pages/Members';
import News from './Pages/News';

import NewsPageOne from './Pages/NewsPageOne';

import ModalForm from './Components/ModalForm';

import { useResize } from './Hooks/useResize';

function App() {
	//useResize кастом хук для отслеживания рамзеров окна
	const { isScreenLg } = useResize();
	//открыие и закрытие меню в мобильной версии
	const [isMenuOpen, setMenuOpen] = React.useState(false);

	const onTapOpen = () => {
		document.body.style.overflow = 'hidden';
		setMenuOpen(true);
	};
	const onTapClose = () => {
		document.body.style.overflow = 'unset';
		setMenuOpen(false);
	};
	//открытие модалки
	const [isModalOpen, setIsModalOpen] = React.useState(false);
	const showModal = () => {
		setIsModalOpen(true);
	};
	const closeModal = () => {
		setIsModalOpen(false);
	};

	return (
		<div
			className="wrapper"
			style={isModalOpen ? { position: 'relative' } : { position: 'static' }}>
			{isModalOpen && <ModalForm isModalOpen={isModalOpen} closeModal={closeModal} />}
			{isScreenLg ? (
				<Header />
			) : (
				<>
					<MobileHeader isMenuOpen={isMenuOpen} onTapClose={onTapClose} onTapOpen={onTapOpen} />
					<MobileMenu isMenuOpen={isMenuOpen} onTapClose={onTapClose} />
				</>
			)}

			<Routes>
				<Route path="/" element={<Home showModal={showModal} />} />
				<Route path="/about" element={<About />} />
				<Route path="/news" element={<News />} />
				<Route path="/members" element={<Members showModal={showModal} />} />
				<Route path="/docs" element={<Docs />} />
				<Route path="/contacts" element={<Contacts />} />
				<Route path="/newspageone" element={<NewsPageOne />} />
			</Routes>
			<Footer />
		</div>
	);
}

export default App;
